import { DISQUALIFY_REASONS } from "constants/globalConstants";
import { useEffect } from "react";
import { useIsMobileDevice } from "./useWindowDimensions";

export const useFullScreen = ({
  setIsFullScreenEnabled,
  isFullScreenEnabled,
  isFullScreen,
  setFullScreenWarningVisible,
  setUserDisqualificationReason,
}) => {
  const isMobile = useIsMobileDevice()

  useEffect(() => {
    if (!document?.fullscreenElement || !document?.webkitFullscreenElement || !document?.webkitCurrentFullScreenElement || !document?.mozFullScreenElement) {
      handleFullScreenChange()
    }
    document.addEventListener("fullscreenchange", handleFullScreenChange)
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange)
    document.addEventListener("mozfullscreenchange", handleFullScreenChange)
    document.addEventListener("msfullscreenchange", handleFullScreenChange)
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange)
      document.removeEventListener("webkitfullscreenchange", handleFullScreenChange)
      document.removeEventListener("mozfullscreenchange", handleFullScreenChange)
      document.removeEventListener("msfullscreenchange", handleFullScreenChange)
    }
  }, [])

  /**
   * visibilitychange moved from useTabSwitch hook to this hook.
   * Add this event listener only if it is fullscreen. 
   * Along with blur and focus, keep visibilitychange as well to cover all the cases for 
   * disqualification just like before.
   */
  useEffect(() => {
    if (isFullScreen) {
      document.addEventListener("visibilitychange", handleVisibilityChange)
      return () => document.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, [isFullScreen])

  /**
   * This hook is moved from GameLoader to this hook.
   * Show warning if user tries to exit full screen by clicking the exit button or Esc. 
   */
  useEffect(() => {
    if (isFullScreenEnabled && !isMobile) {
      setFullScreenWarningVisible(false)
    } else if (!isFullScreenEnabled && isFullScreen && !isMobile) {
      setFullScreenWarningVisible(true)
      window.addEventListener("blur", handleBlur)
    }
    return () => window.removeEventListener("blur", handleBlur)
  }, [isFullScreenEnabled, isFullScreen, isMobile])

  const handleFullScreenChange = (event) => {
    if (document?.fullscreenElement || document?.webkitFullscreenElement || document?.webkitCurrentFullScreenElement || document?.mozFullScreenElement) {
      setIsFullScreenEnabled(true)
    } else {
      setIsFullScreenEnabled(false)
    }
  }

  const handleVisibilityChange = () => {
    setTimeout(() => {
      if (document.visibilityState === 'hidden') {
        disqualifyFullScreen()
      }
    }, 0)
  }

  /**
   * This function handles blur on GC after the user has escaped the full screen mode using Esc or refresh.
   * In this case, the activeElement is the fullscreen warning modal.
   */
  const handleBlur = () => {
    const currentlyFocussedElement = document.activeElement
    if (currentlyFocussedElement.className.includes("ant-modal-wrap") 
      || (currentlyFocussedElement.tabIndex === 0 && currentlyFocussedElement.getAttribute('aria-hidden') == 'true')
    ) {
      disqualifyFullScreen()
    }
  }

  /**
   * Check position config flag for full screen before disqualifying.
   */
  const disqualifyFullScreen = () => {
    if (isFullScreen && !isMobile) {
      setUserDisqualificationReason(DISQUALIFY_REASONS.FULLSCREEN)
    }
  }

  return {
    disqualifyFullScreen
  }
}


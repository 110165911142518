export const EVENT_STATUS = {
  GAME_MOUNT: 'GAME_MOUNT',
  GAME_DATA_SET: 'GAME_DATA_SET',
  GAME_DATA_SAVE: 'GAME_DATA_SAVE',
  GAME_END: 'GAME_END',
  GAME_CREATE_MASTER_SESSION: "GAME_CREATE_MASTER_SESSION",
  SEND_GAME_RESPONSE: 'SEND_GAME_RESPONSE',
  SEND_GAME_AUDIO_SAVE_RESPONSE: 'SEND_GAME_AUDIO_SAVE_RESPONSE',
  SEND_MCQ_SAVE_RESPONSE: 'SEND_MCQ_SAVE_RESPONSE',
  GAME_BLURRED: "GAME_BLURRED",
  SEND_CHAT_SAVE_RESPONSE: 'SEND_CHAT_SAVE_RESPONSE',


  // Event for parent application when this app plays within an iframe
  GAMEPLAY_END: 'GAMEPLAY_END'
};

export const INTERNET_STATUS = {
  FILE_URL:
    '/assets/bandwidth.png',
  INTERVAL_TIME: 30000,
};

export const GAME_TYPE = {
  QUIZ: 'QUIZ',
  GAME: 'GAME',
  CODE: 'CODE'
};

export const CODE = {
  CODE_SUBMIT: "CODE_SUBMIT",
  CODE_SESSION: "CODE_SESSION"
};
/**
 * Maintaining groups within a category to maintain the UI grid. Each group will start from the next row
 * therefore avoiding the UI complications.
 */
export const formInputGroups = {
    "Personal Details": {
        group1: {
            alternateContact: {
                placeholder: "Alternate Contact Number",
                type: "phone"
            },
            dob: {
                placeholder: "Date Of Birth",
                type: "date"
            },
        },
        group2: {
            currentResidential: {
                placeholder: "Current Residential Address",
                type: "textarea"
            },
            permanentResidential: {
                placeholder: "Permanent Residential Address",
                type: "textarea"
            },
            city: {
                placeholder: "City",
                type: "text"
            },
            pin: {
                placeholder: "Pin Code",
                type: "number"
            }
        },
        group3: {
            language: {
                placeholder: "Language Spoken",
                type: "text"
            },
        },
        group4: {
            gender: {
                placeholder: "Gender",
                type: "radio"
            },
        }
    },
    "University Details": {
        group1: {
            universityRegistrationNumber: {
                placeholder: "URN/USN/UID",
                type: "text"
            },
            universitySeatNumber: {
                placeholder: "University Seat Number",
                type: "text"
            },
        },
    },
    "Educational Qualification": {
        group1: {
            highestQualification: {
                placeholder: "Highest Qualification",
                type: "text"
            },
            stream: {
                placeholder: "Stream/Specialization",
                type: "text"
            },
            collegeName: {
                placeholder: "College Name",
                type: "text"
            },
            percentage: {
                placeholder: "Percentage/CGPA",
                type: "text"
            },
            yearOfPassing: {
                placeholder: "Year of Passing (for highest qualification)",
                type: "year"
            },
        },
        group2: {
            twelfthPercentage: {
                placeholder: "12th Percentage",
                type: "text"
            },
            twelfthYearOfPassing: {
                placeholder: "Year of Passing",
                type: "year"
            },
        },
        group3: {
            tenthPercentage: {
                placeholder: "10th Percentage",
                type: "text"
            },
            tenthYearOfPassing: {
                placeholder: "Year of Passing",
                type: "year"
            },
        }
    },
    "Job Details": {
        group1: {
            yearsOfExp: {
                placeholder: "Years of Experience",
                type: "number"
            },
            compensation: {
                placeholder: "Expected Compensation (lpa)",
                type: "number"
            },
            company: {
                placeholder: "Last or Current Company",
                type: "text"
            },
            noticePeriod: {
                placeholder: "Notice Period (in days)",
                type: "number"
            },
        },
        group2: {
            locationPreference: {
                placeholder: "Preferred locations",
                type: "multi-select"
            }
        },
        group3: {
            edpId: {
                placeholder: "EDP ID",
                type: "text",
                tooltipText: `Please mention your  issued Godrej Employee ID (see your employee badge). If you do not have a Godrej Employee ID, please mention as N/A`
            },
        },
        group4: {
            zoneName: {
                placeholder: "Zone Name",
                type: "single-select"
            },
            site: {
                placeholder: "Site",
                type: "single-select"
            },
        }
    },
    "Upload Resume": {
        group1: {
            resumeUpload: {
                placeholder: "Select a PDF, DOC, WORD file.",
                type: "file"
            }
        }
    }
}

export const initialValues = {
    alternateContact: "",
    dob: "",
    currentResidential: "",
    permanentResidential: "",
    gender: "",
    highestQualification: "",
    stream: "",
    collegeName: "",
    percentage: "",
    yearOfPassing: "",
    twelfthPercentage: "",
    twelfthYearOfPassing: "",
    tenthPercentage: "",
    tenthYearOfPassing: "",
    resumeUpload: "",
    universitySeatNumber: "",
    universityRegistrationNumber: "",
    language: "",
    yearsOfExp: "",
    compensation: "",
    locationPreference: "",
    edpId: "",
    zoneName: "",
    site: "",
    city: "",
    pin: "",
    company: "",
    noticePeriod: "",
}

export const acceptedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]

const locationOptions = ["Mumbai", "Pune", "Chennai", "Bengaluru", "Hyderabad", "Coimbatore", "Kolkata"]

const zoneOptions = ["North", "South", "Mumbai/MMR", "West-East"]

export const dropdownInputOptions = {
    zoneName: zoneOptions,
    locationPreference: locationOptions,
    "North-site": [
        "ASHOK VIHAR",
        "FARIDABAD",
        "GODREJ AIR",
        "GODREJ ARIA/101",
        "GODREJ GOLF LINK",
        "GODREJ HABITAT",
        "GODREJ KURUKSHETRA",
        "GODREJ MERIDIEN",
        "GODREJ NATURE PLUS",
        "GODREJ NEST & NURTURE",
        "GODREJ PALM RETREAT",
        "GODREJ SECTOR 103",
        "GODREJ SECTOR 146",
        "GODREJ SECTOR 43 GGN",
        "GODREJ SECTOR 49",
        "GODREJ SECTOR 54",
        "GODREJ SECTOR 89",
        "GODREJ SOUTH ESTATE",
        "GODREJ SUMMIT",
        "GODREJ TDI",
        "GODREJ WOODS",
        "OASIS/ICON",
        "SONIPAT",
    ],
    "South-site": [
        "RGA TECH PARK",
        "GODREJ AVENUES",
        "BENGAL LAMPS",
        "GODREJ ROYALE WOODS",
        "GODREJ ETERNITY",
        "AGROVET",
        "BALGUR",
        "GODREJ NURTURE",
        "WHITEFIELD GARDEN CITY",
        "ORAGADAM",
        "KOKAPET",
        "VIBHUTHIPURA",
        "BANNERGHATA",
        "YESHWANTHPUR",
        "BEL HYDERABAD",
        "BANGALORE RO",
        "RGA 2",
        "GODREJ PARK RETREAT",
        "WOODSCAPE",
        "WOODLAND",
        "ANANDA",
        "ATHENA",
        "GODREJ ORAGADAM",
        "SPLENDOUR"
    ],
    "Mumbai/MMR-site": [
        "BHANDUP",
        "CARMICHAEL",
        "GODREJ ASCEND",
        "GODREJ AVENUE 11",
        "GODREJ BAYVIEW",
        "GODREJ CITY",
        "GODREJ EMERALD",
        "GODREJ EXQUISITE",
        "GODREJ FIVE GARDENS",
        "GODREJ HORIZON",
        "GODREJ NIRVAAN",
        "GODREJ PRIME",
        "GODREJ RESERVE",
        "GODREJ RIVIERA",
        "GODREJ RKS",
        "GODREJ SKY TERRACES",
        "GODREJ TRANQUIL",
        "GODREJ UPAVAN",
        "GODREJ URBAN PARK",
        "GODREJ VIHAA",
        "IMAGICA",
        "KHALAPUR",
        "MANOR"
    ],
    "West-East-site": [
        "GODREJ SECTOR 146",
        "GODREJ MERIDIEN",
        "GODREJ AIR",
        "GODREJ SUMMIT",
        "GODREJ NATURE PLUS",
        "GODREJ SECTOR 43 GGN",
        "SONIPAT",
        "GODREJ NEST & NURTURE",
        "GODREJ SOUTH ESTATE",
        "GODREJ KURUKSHETRA",
        "GODREJ SECTOR 49",
        "GODREJ SECTOR 103",
        "GODREJ HABITAT",
        "GODREJ SECTOR 89",
        "GODREJ TDI",
        "GODREJ GOLF LINK",
        "GODREJ PALM RETREAT",
        "GODREJ WOODS",
        "GODREJ ARIA/101",
        "ASHOK VIHAR",
        "GODREJ SECTOR 54",
        "FARIDABAD",
        "OASIS/ICON",
        "NAGPUR",
        "MAMURDI",
        "MAHALUNGE",
        "MANJARI",
        "GGC",
        "KP NX",
        "SE7EN",
        "ICC",
        "MAAN-HINJE",
        "PRAKRITI",
        "PUNE RO",
        "NAGPUR 2",
        "MUNDHWA",
        "BL SAHA",
        "MAAN HINJE",
        "ICC - PIMPRI",
        "MUNDHAWA",
        "ZO",
        "KP2",
        "KESHAVNAGAR",
    ],
}

export const dropdownInputMode = {
    zoneName: "single",
    site: "single",
    locationPreference: "multiple",
}
import { ORG_SET_EVENT } from "constants/globalConstants"
import { createContext, useContext, useState } from "react"
import endpoints from "services/apis/gameplay"
import { storage } from "services/config/storage"

const GameplayConfigContext = createContext(null)

export const GameplayConfigProvider = ({ children }) => {
    const [allowMobileView, setAllowMobileView] = useState(true)
    const [isProctored, setIsProctored] = useState(false)
    const [isFullScreen, setIsFullScreen] = useState(false)
    const [tabSwitchLimit, setTabSwitchLimit] = useState(Number.MAX_SAFE_INTEGER)
    const [isFeedbackVisible, setIsFeedbackVisible] = useState(true)
    const [snapshotInterval, setSnapshotInterval] = useState(60)
    const [isVideoProctored, setIsVideoProctored] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [positionId, setPositionId] = useState("")
    const [customizableForm, setCustomizableForm] = useState(true)
    const { pathname } = window.location

    async function fetchPositionConfig(idType, id) {
        try {
            const configRes = await endpoints.getGameplayConfig(idType, id)
            return configRes
        } catch (err) {
            console.error('Error in fetching gameplay config :: ', err)
        } finally {
            storage.destroy.configStat()
        }
    }

    async function fetchLinkConfig(id) {
        try {
            const configRes = await endpoints.getLinkPositionConfig(id)
            return configRes
        } catch (err) {
            console.error('Error in fetching gameplay config :: ', err)
        } finally {
        }
    }

    async function fetchConfig() {
        try {
            const id = pathname.split('/').pop()
            const page = pathname.split('/')[1]
            const idType = ["login", "signup"].includes(page) ? "positionId" : "linkId"
            if (id && (page !== "system-check" && page !== "report")) {
                setIsloading(true)
                const [positionConfigRes, linkConfigRes] = await Promise.allSettled([
                    fetchPositionConfig(idType, id),
                    fetchLinkConfig(id)
                ])
                if (positionConfigRes?.value?.success || linkConfigRes?.value?.success) {
                    const { mobile,
                        proctoring,
                        fullScreen,
                        maxTabSwitch,
                        feedback,
                        loginType,
                        positionId,
                        saveProctoredVideo,
                        snapshotTimeInterval,
                        organization,
                        customizableForm } = positionConfigRes?.value?.positionConfig ?? {}
                    const { media } = positionConfigRes?.value?.positionConfig?.organization ?? {}
                    setPositionId(positionId)
                    setAllowMobileView(!!mobile)
                    if (linkConfigRes?.value?.linkPositionConfig) {
                        setIsProctored(!!linkConfigRes?.value?.linkPositionConfig?.proctoring)
                        setIsVideoProctored(!!linkConfigRes?.value?.linkPositionConfig?.saveProctoredVideo)
                    } else {
                        setIsProctored(!!proctoring)
                        setIsVideoProctored(!!saveProctoredVideo)
                    }
                    setIsFullScreen(!!fullScreen)
                    setIsFeedbackVisible(!!feedback)
                    setSnapshotInterval(snapshotTimeInterval)
                    setCustomizableForm(customizableForm)
                    if (maxTabSwitch) {
                        setTabSwitchLimit(maxTabSwitch)
                    }
                    if (loginType) {
                        storage.set.loginType(loginType)
                    }
                    if (media?.link) {
                        storage.set.companiesLogos(media?.link ?? "")
                    } else {
                        storage.destroy.companiesLogos()
                    }
                    if (organization) {
                        storage.set.orgInfo(organization);
                        window.dispatchEvent(new Event(ORG_SET_EVENT));
                    }
                }
            }
        } catch (err) {
            console.error("Error in fetching configs ::", err)
        } finally {
            setIsloading(false)
        }
    }

    return (
        <GameplayConfigContext.Provider
            value={{
                allowMobileView,
                isProctored,
                isFullScreen,
                tabSwitchLimit,
                isLoading,
                isFeedbackVisible,
                positionId,
                snapshotInterval,
                isVideoProctored,
                customizableForm,
                fetchConfig,
                fetchPositionConfig,
                fetchLinkConfig,
                setIsVideoProctored,
                setIsProctored,
                setIsFeedbackVisible,
            }}
        >
            {children}
        </GameplayConfigContext.Provider>
    )

}

export const useGameplayConfig = () => useContext(GameplayConfigContext)
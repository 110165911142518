import { useMemo, useState } from "react"
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { CaretRightFilled, CaretLeftFilled } from "@ant-design/icons";
import "react-datepicker/dist/react-datepicker.css";
import "./style.scss"

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]
const currentYear = Number(new Date().getFullYear())

const DatePicker = ({
    startingYear = 1990,
    endingYear = currentYear,
    setFormFieldValue
}) => {
    const [date, setDate] = useState(null)
    const years = useMemo(() => Array.from({ length: endingYear - startingYear + 1 }, (_, index) => startingYear + index)
        , [startingYear])

    return (
        <>
            <ReactDatePicker
                dateFormat="dd/MM/yyyy"
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                }) => (
                    <div className="flex justify-between"
                        style={{
                            padding: "0px 4px",
                            width: "100%"
                        }}
                    >
                        <div style={{
                            width: "100%"
                        }} className="flex items-center justify-between">

                            <div className="flex items-center">
                                <span className="cursor-pointer mr-4 flex items-center month-caret" onClick={decreaseMonth}>
                                    <CaretLeftFilled className="size-18" />
                                </span>
                                <select
                                    value={months[Number(moment(date).format("M")) - 1]}
                                    onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                    }
                                    className="months-select"
                                >
                                    {months.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <span className="cursor-pointer ml-4 flex items-center month-caret" onClick={increaseMonth}>
                                    <CaretRightFilled className="size-18" />
                                </span>
                            </div>

                            <div className="separator" />

                            <select
                                value={date === null ? startingYear : moment(date).format("YYYY")}
                                onChange={({ target: { value } }) => changeYear(value)}
                                className="years-select"
                            >
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>
                )}
                selected={date}
                onChange={(date) => {
                    if (date === null) {
                        setDate(date)
                        setFormFieldValue(date)
                    } else {
                        let updatedDate = String(date)
                        const yearInDate = Number(updatedDate.split(" ")[3])
                        if (yearInDate > endingYear || yearInDate < startingYear) {
                            updatedDate = updatedDate
                                .split(" ").map((_, index) => {
                                    if (index === 3) return `${startingYear}`
                                    return _
                                })
                                .join(" ")
                        }
                        setDate(updatedDate)
                        setFormFieldValue(updatedDate)
                    }
                }}
                placeholderText="Date of Birth (DD/MM/YYYY)"
            />
        </>
    )
}

export default DatePicker
import { Api } from "services/config/request"

const candidateFormApi = {
  getCandidateFormInputs(positionId) {
    return Api.get(`/api/position/v1/${positionId}/customizable-form`)
  },
  saveCandidateForm(linkId, payload) {
    return Api.post(`/api/session/v1/${linkId}/candidate-form`, payload)
  },
  getCandidateFormData(linkId) {
    return Api.get(`/api/session/v1/${linkId}/candidate-form-data`)
  }
}

export default candidateFormApi
import { Redirect, useHistory, Route } from 'react-router-dom';
import { storage } from '../services/config/storage';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const isLoggedIn = !!storage.get.authToken();
    const isUserDisqualified = !!storage.get.userDisqualified()
    const { location: { pathname } } = useHistory();

    const viewName = pathname.match(/\/\w+/g)[0].slice(1,)

    return <Route {...rest} render={(props) =>
        !isLoggedIn || isUserDisqualified ?
            <Redirect to={{
                pathname: pathname.replace(viewName, 'home')
            }} />
            : <Component {...rest} {...props} />
    } />
}

export default ProtectedRoute
import { Button } from 'antd';

import UnberryModal from 'components/dumb/UnberryModal';
import * as LoadingScreenUtils from '../../../views/LoadingScreen/screenUtils';
import { VIEW_CONSTANTS } from 'constants/helperModalConstants';

const modalBodyStyles = {
	...LoadingScreenUtils.modalBodyStyles,
	padding: '50px 60px 60px 60px',
};

const modalMaskStyles = {
	...LoadingScreenUtils.modalMaskStyles,
}

const InstructionsModal = ({ visible, view, onClick }) => {
	return (
		<UnberryModal
			visible={visible}
			footer={false}
			width={'430px'}
			bodyStyle={modalBodyStyles}
			maskStyle={modalMaskStyles}
			closable={false}
			keyboard={false}
		>
			{
				view === VIEW_CONSTANTS.COOKIE_POLICY_VIEW ? (
					<div className="verify-otp">
						<p className="color-primary font-bold size-30 mb-20" style={{ letterSpacing: '-1.5808px' }}>Cookies Policy</p>
						<p className="color-primary font-medium mb-25 verify">
							To enhance your experience, <span className="font-bold color-primary">we</span> use cookies (or equivalent technologies)</p>
					
						<p className="color-primary font-medium mb-15 verify">
							Kindly follow below instructions to enable use of cookies for a smooth gameplay.</p>
					
						<p className="color-primary font-medium mb-10 verify">
							1. Open the settings tab in your browser (Chrome, Firefox, Safari).</p>
					
						<p className="color-primary font-medium mb-10 verify">
							2. Head over to the privacy section in settings and enable use of cookies.</p>
					
						<p className="color-primary font-medium mb-10 verify">
							3. Refresh browser window to apply changes.</p>
							
						<Button 
							type="primary" 
							className="font-medium mt-20"
							block 
							onClick={onClick}
						>
							Refresh
						</Button>
					</div>
				) : view === VIEW_CONSTANTS.TOKEN_EXPIRY_VIEW ? (
					<div className="verify-otp">
						<p className="color-primary font-bold size-30 mb-20" style={{ letterSpacing: '-1.5808px' }}>Login Expired</p>
						<p className="color-primary font-medium mb-25 verify">
							Due to long period of inactivity or login from another browser, your session has expired.</p>
				
						<p className="color-primary font-medium mb-15 verify">
							Login again by clicking the button below or by simply refreshing the current window.</p>
							
						<Button 
							type="primary" 
							className="font-medium mt-20"
							block 
							onClick={onClick}
						>
							Back to Login
						</Button>
					</div>
				) : null 
			}
		</UnberryModal>
	);
}
	

export default InstructionsModal;
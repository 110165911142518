/* Modal to render out any of the Unberry's modal components. Add in the children to the modal */

import React from 'react';
import { Modal } from 'antd';

const UnberryModal = (props) => {
  return (
    <Modal
      style={{
        ...(props.bottomAligned && {
          top: `calc(${window.innerHeight}px - 450px)`,
        }),
      }}
      centered={props.bottomAligned ? false : true}
      destroyOnClose
      {...props}
    >
      {props.children}
    </Modal>
  );
};

export default UnberryModal;

import { useParams } from "react-router-dom"
import "./styles.scss"

const Report = () => {
    const { userId, linkId } = useParams()

    return (
        <div id="report-window">
            <iframe
                id="report-frame"
                src={`${process.env.REACT_APP_DASHBOARD_ENDPOINT}/candidate-report/link/${linkId}/users/${userId}`}
                title="resultFrame"
                allow="clipboard-write"
            />
        </div>
    )
}

export default Report
import { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Tooltip } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";

import HeroPageTemplate from 'components/dumb/HeroPageTemplate';
import Toast from 'components/dumb/Toast';
import UserForm from 'components/smart/UserForm';
import { useHistory, useParams } from 'react-router-dom';
import endpoints from 'services/apis/auth';
import { validators } from './validate';

import './style.scss';
import { storage } from 'services/config/storage';
import { useIsMobileDevice } from 'hooks';
import { COUNTRY_DATA, DEFAULT_COUNTRY_DATA, GODREJ_SECTION_IDS, LOGIN_TYPE, ORG_SET_EVENT } from 'constants/globalConstants';
import PhoneInput from 'components/smart/PhoneInput';
import { ErrorWarning } from 'assets/icons';
import { initializeFreshChat } from 'services/freshchat';
import { CustomTooltip } from 'components/dumb/CustomTooltip';

const FORM_INITIAL_VALUES = {
  name: '',
  email: '',
  candidateKey: '',
  phone: undefined,
  experience: null,
};

const formItemLayout = {
  wrapperCol: { span: 24 },
};

const SignUp = () => {

  const { validateEmail, handleKeyPress } = validators;
  const companyLogo = storage.get.companiesLogos();

  const [userDetailForm] = Form.useForm();
  const { orgType, orgId, positionId } = useParams();
  const isMobile = useIsMobileDevice();

  const history = useHistory();
  const loginType = storage.get.loginType();
  const orgInfo = storage.get.orgInfo()

  const hasPhoneLogin = (loginType === LOGIN_TYPE.PHONE);
  const hasThirdPartyLogin = (loginType === LOGIN_TYPE.THIRD_PARTY);

  // Check and make changes for godrej ids
  const hasGodrejPosition = GODREJ_SECTION_IDS.includes(positionId);
  const heroPageTemplateTitle = hasGodrejPosition ?
    //  "GPL ALCHEMY || OPERATIONS KNOWLEDGE ASSESSMENT 2024" :
    "GPL Alchemy: Post Workshop Assessment : Sequence Essentials" : 
    isMobile ? ['Welcome', <br />, 'aboard! 👋🏻'] : "Welcome aboard! 👋🏻";

  const [signUpLink, setSignUpLink] = useState(null);
  const [country, setCountry] = useState(DEFAULT_COUNTRY_DATA);
  const [signupError, setSignupError] = useState(null);

  // ===================
  // functions
  // ===================
  const redirectToOTP = ({ linkId, email, candidateKey, phone = null, country = {} }) => {
    storage.set.configStat(true);
    history.push(`/home/${linkId}`, {
      email,
      posId: positionId,
      ...((hasPhoneLogin || phone) && { phone }),
      ...((hasPhoneLogin || phone) && { country }),
      ...(hasThirdPartyLogin && { candidateKey })
    });
  };

  const handleFormSubmition = async (formData) => {
    try {
      if (!formData.phone) {
        delete formData.phone;
      } else {
        formData.phone = String(formData.phone);
      }
      if (!hasThirdPartyLogin) delete formData.candidateKey;
      if (!formData.email) delete formData.email;
      const payload = {
        ...formData,
        positionId,
        ...(formData.phone && { countryCode: country.phone }),
      };
      const response = hasThirdPartyLogin ?
        await endpoints.thirdPartySignup(orgType, orgId, payload) :
        await endpoints.signup(orgType, orgId, payload);
      if (response.success) {
        const { link } = response;
        const [_, linkId] = link.split('/home/');
        setSignUpLink({
          linkId,
          email: formData.email || "",
          posId: positionId,
          ...((hasPhoneLogin || formData.phone) && { phone: formData.phone }),
          ...((hasPhoneLogin || formData.phone) && { country }),
          ...(hasThirdPartyLogin && { candidateKey: formData.candidateKey })
        });
      }
    } catch (err) {
      console.log('err :>> ', err);
      // user already present: redirect to same otp screen
      if (err.metaData?.linkId) {
        redirectToOTP({
          linkId: err.metaData.linkId,
          email: formData.email || "",
          posId: positionId,
          ...((hasPhoneLogin || formData.phone) && { phone: formData.phone }),
          ...((hasPhoneLogin || formData.phone) && { country }),
          ...(hasThirdPartyLogin && { candidateKey: formData.candidateKey })
        });
      } else {
        setSignupError(err?.message);
        console.error('Error in signup: ', err);
      }
    }
  };

  // ===================
  // useEffects
  // ===================
  /**Dynamic country code from org config as per Neha. */
  useEffect(() => {
    const countryCode = orgInfo?.organizationConfig?.countryCode ?? "91"
    const country = COUNTRY_DATA?.find(cuntry => cuntry?.phone == countryCode)
    setCountry(country)
    const initiateFC = () => {
      if (!window.fcWidget?.isInitialized()) {
        const data = storage.get.fcData() ?? null;
        initializeFreshChat(data, isMobile);
      }
    };
    window.addEventListener(ORG_SET_EVENT, () => {
      console.log("Initialize FC");
      initiateFC();
    });
    return () => window.removeEventListener(ORG_SET_EVENT, initiateFC);
  }, []);

  return !signUpLink ? (
    <section className={`${isMobile ? "home-container" : "home-container overflowX-hidden bg-color-primary flex flex-col justify-between items-center pt-25 pb-25"}`}>
      <HeroPageTemplate
        headingSize={hasGodrejPosition ? 40: 60}
        companyLogo={companyLogo}
        isMobile={isMobile}
        heading={heroPageTemplateTitle}
        content="Hello! We’re so excited to have you participate in this experience. Take a deep breath and jump right in by entering your details below."
        hasGodrejPosition={hasGodrejPosition}
      >
        <UserForm
          {...formItemLayout}
          form={userDetailForm}
          autoComplete="off"
          autoCapitalize="off"
          className="signup-form"
          submitForm={handleFormSubmition}
          formInitalValues={FORM_INITIAL_VALUES}
        >
          <div className={`${isMobile ? "" : "form"}`}>
            {
              signupError ?
                <p className="error color-primary size-14 mb-10 p-10 flex items-center">
                  <span className='flex items-center mr-4'>
                    <ErrorWarning />
                  </span>
                  {signupError}
                </p> : <></>
            }
            <Row gutter={[10, 15]}>
              <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your name',
                    },
                  ]}
                >
                  <Input placeholder="Please mention your full name" />
                </Form.Item>
              </Col>
              {
                hasThirdPartyLogin &&
                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <Form.Item
                    name="candidateKey"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter candidate ID',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your candidate ID"
                      onPaste={({ target: { value } }) => { userDetailForm.setFieldsValue({ candidateKey: value.trim() }); }}
                      onChange={({ target: { value } }) => { userDetailForm.setFieldsValue({ candidateKey: value.trim() }); }}
                    />
                  </Form.Item>
                </Col>
              }
              <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: !(hasPhoneLogin) || hasGodrejPosition,
                      message: 'Please enter an email',
                    },
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <Input
                    placeholder={`${hasPhoneLogin && !hasGodrejPosition ? 'Enter email ID (optional)' : 'Enter your email ID'}`}
                    onPaste={({ target: { value } }) => { userDetailForm.setFieldsValue({ email: value.trim() }); }}
                    onChange={({ target: { value } }) => { userDetailForm.setFieldsValue({ email: value.trim() }); }}
                  />
                </Form.Item>
              </Col>
              {
                hasGodrejPosition &&
                <div className='email-info-tooltip-container' style={{ position: 'relative' }}>
                  <CustomTooltip
                    title={`Please mention your Godrej Email ID. 
                    Only if you do not have a Godrej Email ID, please mention your personal email ID. Do NOT mention NA.`
                    }
                    showArrow={false}
                  >
                    <InfoCircleOutlined style={{ fontSize: '24px', color: 'white', position: "relative", top: "14px", cursor: "pointer" }} />
                  </CustomTooltip>
                </div>
              }
              <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                <Form.Item name="phone">
                  <PhoneInput
                    placeholder="Phone Number"
                    className="signup-phone"
                    overlayClassName="signup-form"
                    setValue={(value) => userDetailForm.setFieldValue("phone", value)}
                    country={country}
                    hasPhoneLogin={hasPhoneLogin}
                    setCountry={setCountry}
                  />
                </Form.Item>
              </Col>
              {
                !hasThirdPartyLogin &&
                <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                  <Form.Item
                    name="yearsOfExperience"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter experience (in years)',
                      },
                    ]}
                  >
                    <InputNumber
                      name="yearsOfExperience"
                      controls={false}
                      step={0.1}
                      min={0}
                      onKeyPress={handleKeyPress}
                      placeholder="Overall Experience"
                    />
                  </Form.Item>
                </Col>
              }
              {
                !isMobile ?
                  <Col xs={{ span: 22 }} lg={{ span: 11 }}>
                    <Form.Item className='signup-continue-btn flex'>
                      <Button className="" type="primary" htmlType="submit">
                        Continue
                      </Button>
                    </Form.Item>
                  </Col> : <></>
              }
            </Row>
            {
              isMobile ? <Form.Item>
                <div className="mobile-continue-btn">
                  <Button
                    className="font-medium br-20 flex justify-center"
                    type="primary"
                    htmlType="submit"
                  >
                    Continue
                  </Button>
                </div>
              </Form.Item> : <></>
            }
          </div>
        </UserForm>
      </HeroPageTemplate>
    </section>
  ) : (
    <HeroPageTemplate
      companyLogo={companyLogo}
      heading="🎉 Yay! You have been successfully registered"
      content="Click on continue to begin your gameplay."
      headingSize={60}
      isMobile={isMobile}
      hasGodrejPosition={hasGodrejPosition}
    >
      <div className='signup-continue-btn absolute flex justify-center'>
        <Button
          className="mt-22"
          type="primary"
          onClick={() => redirectToOTP(signUpLink)}
        >
          Continue
        </Button>
      </div>
    </HeroPageTemplate>
  );
};

export default SignUp;

import React from 'react';

export const GodrejSection = () => {
	return (
		<div className={`flex flex-col justify-center items-start`}>
			{/* <p className="color-primary weight-400 size-32 mb-24">
				Welcome to the Operations Knowledge Assessment organised as part of our Alchemy Initiative.
			</p> */}
			<p className="color-primary weight-400 size-32 mb-24">
				Welcome to the Post-Workshop Knowledge Assessment!
			</p>
			<p className="color-primary weight-500 size-24 mb-4">
				Purpose
			</p>
			<p className="color-primary weight-400 opacity-70 size-16 mb-24">
				To understand your progress in learning the concepts explained during the workshop "Sequence Essentials".
			</p>
			{/* <p className="color-primary weight-400 opacity-70 size-14 mb-20 ">
				Please note that the assessment has no impact on your performance reviews or appraisals.
			</p> */}
			<p className="color-primary weight-500 size-24 mb-4">
				Test Format
			</p>
			<p className="color-primary weight-400 opacity-70 size-16 mb-32">
				The Assessment consists of 40 Multiple Choice Questions (Single Option Correct) and you will get <span className='size 16' style={{ color: 'white' }}><i><strong>60 minutes</strong></i></span> to complete the assessment. Please note the Test window is strictly limited within the time slots as indicated during the workshop to you. The Assessment can only be taken once and will not be available beyond the indicated time window.
			</p>
			<p className="color-primary weight-500 size-24 mb-24 ">
				All the best!
			</p>
		</div>
	);
};

import React from 'react';
import './style.scss';

const AssessmentSections = () => {
	const data = [
		{ section: 'Coding Question 1', time: '20mins' },
		{ section: 'Coding Question 2', time: '20mins' },
		{ section: 'Aptitude MCQ (20 questions)', time: '25mins' },
		{ section: 'DSA MCQ (5 questions)', time: '10mins' }
	];

	return (
		<div className="assessment-section">
			<div className="table-container">
				<table className="styled-table">
					<thead>
						<tr>
							<th>Sections</th>
							<th>Time Allocated</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item, index) => (
							<tr key={index}>
								<td>{item.section}</td>
								<td>{item.time}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AssessmentSections;
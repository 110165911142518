import { Api } from '../config/request';

const endpoints = {
  sendOtp(data) {
    return Api.post(`/api/user/v1/otp`, data);
  },
  verifyOtp(data) {
    return Api.post(`/api/user/v1/login`, data);
  },
  verifyThirdPartyLogin(data) {
    return Api.post(`/api/user/v1/third-party/login`, data);
  },
  getUserInfo() {
    return Api.get(`/api/user/v1/get-user-info`);
  },
  signup(orgType, orgId, data) {
    return Api.post(
      `/api/link/v1/${orgType}/${orgId}/public-user-invite`,
      data
    );
  },
  thirdPartySignup(orgType, orgId, data) {
    return Api.post(
      `/api/link/v1/${orgType}/${orgId}/public-user-invite-third-party`,
      data
    );
  },
};

export default endpoints;

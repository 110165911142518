import { useEffect } from "react";
import { storage } from 'services/config/storage';
import {
    createGameplayModuleMasterSession,
    getChatMessage,
    getCodingProblemData,
    getCodingSession,
    getGameLevelData,
    getQuizData,
    markQuizComplete,
    saveCodingAnswer,
    saveCodingSession,
    saveGameLevelData,
    saveQuizAnswer,
    uploadAudioAnswer,
} from 'utils/helper';
import { CODE, EVENT_STATUS, GAME_TYPE } from 'utils/consts';
import { GC } from "utils/postMessage";
import { PAUSE_TIMER_EVENT } from "constants/helperModalConstants";
import { ORGANIZATIONS_NAME } from "constants/globalConstants";

export const useGamesLifecycle = ({
    allowedOrigins,
    currentGame,
    linkId,
    loaderRef,
    playedGamesIds,
    initGame,
    pauseGameTimerOnMount,
    disqualifyFullScreen
}) => {
    useEffect(() => {
        const messageEventHandler = async (event) => {
            // origin check
            const origins = allowedOrigins;
            if (!origins.includes(event.origin)) return;

            console.log('🚀 ~ Assessment centre Event Handler ::', event.data);
            switch (event.data.status) {
                case EVENT_STATUS.GAME_MOUNT: {
                    const gameId = currentGame.id;
                    let gameLevelData =
                        event.data.type === GAME_TYPE.CODE
                            ? await getCodingProblemData(gameId, linkId)
                            : event.data.type === GAME_TYPE.QUIZ
                                ? await getQuizData(gameId, linkId)
                                : await getGameLevelData(gameId, linkId);
                    if (event.data.type === GAME_TYPE.CODE) {
                        const gameSessionData = await getCodingSession(gameId, linkId);
                        if (gameSessionData) {
                            gameLevelData = {
                                ...gameLevelData,
                                ...gameSessionData,
                            };
                        }
                    }
                    const gameDetails = {
                        mediaUrl: currentGame.mediaUrl,
                        gameName: currentGame.name
                    };
                    const user = storage.get.fcData() ?? {};
                    const orgInfo = storage.get.orgInfo()
                    const showGameplayOnly = storage.get.showGameplayOnly();
                    let message = {
                        gameId,
                        authToken: storage.get.authToken(),
                        gameplayId: linkId,
                        gameLevelData,
                        gameDetails,
                        user: { email: user.email, username: user.name, id: user.id },
                    };
                    if (pauseGameTimerOnMount.current) {
                        message.status = `${PAUSE_TIMER_EVENT}-MOUNT`
                    }
                    if (showGameplayOnly && orgInfo?.name?.toLowerCase()?.includes(ORGANIZATIONS_NAME.SPOTLIGHT.toLowerCase())) {
                        message.organization = "Spotlight"
                        message.parent = "GC"
                    }
                    GC.sendMessageToGame({ message, origin: event.origin })
                    break;
                }
                case EVENT_STATUS.GAME_DATA_SET: {
                    if (loaderRef.current) loaderRef.current.style.display = 'none';
                    break;
                }
                case EVENT_STATUS.GAME_CREATE_MASTER_SESSION: {
                    const user = storage.get.fcData() ?? {};
                    const sessionType = event.data.type === GAME_TYPE.CODE
                        ? "code" : event.data.type === GAME_TYPE.QUIZ
                            ? "quiz" : "game";
                    const sessionPayload = {
                        sessionId: currentGame.id,
                        userId: user.id,
                        linkId,
                        sessionType
                    }
                    await createGameplayModuleMasterSession(sessionPayload)
                    break;
                }
                case EVENT_STATUS.GAME_DATA_SAVE: {
                    if (event.data.type === GAME_TYPE.CODE) {
                        const { gameLevelData, saveDataType } = event.data;
                        if (saveDataType === CODE.CODE_SESSION) {
                            const sessionPayload = {
                                ...event.data.gameLevelData,
                                linkId: linkId,
                                problemId: currentGame.id,
                            };
                            await saveCodingSession(sessionPayload);
                            return;
                        }
                        const problemId = currentGame.id;
                        let gameMessage = {
                            gameLevelData: null,
                            messageDataType: '',
                        };
                        try {
                            const response = await saveCodingAnswer({
                                gameLevelData,
                                problemId,
                                linkId,
                                saveDataType,
                            });
                            if (response.success) {
                                // Handle case for user refreshing on Thank you screen
                                // If submission is success, add to listOfPlayedGameIds here since BE is not handling submission response correctly 😔
                                if (saveDataType === CODE.CODE_SUBMIT) {
                                    const gameId = currentGame.id;
                                    let updatedPlayedGameIds = [...playedGamesIds, gameId];
                                    storage.set.listOfPlayedGamesIds(updatedPlayedGameIds);
                                    gameMessage = {
                                        // Send success key here reagardless of whether present or not in response.data
                                        gameLevelData: { ...response.data, success: true },
                                        messageDataType: saveDataType,
                                    };
                                } else {
                                    gameMessage = {
                                        gameLevelData: response.data,
                                        messageDataType: saveDataType,
                                    };
                                }
                            }
                        } catch (error) {
                            console.error(error);
                        }
                        GC.sendMessageToGame({ message: gameMessage, origin: event.origin })
                    } else if (event.data.type === GAME_TYPE.QUIZ) {
                        const { generateChatReply, saveAudioAnswer, ...gameData } = { ...event.data.gameLevelData };
                        delete gameData.isLastQuestion
                        // generateChatReply will be true for getting chat reply for the given set of messages
                        if (generateChatReply) {
                            const messages = await getChatMessage(gameData);
                            // sending api response back to quiz (chat module)
                            GC.sendMessageToGame({
                                message: {
                                    messages: messages,
                                    status: EVENT_STATUS.SEND_GAME_RESPONSE,
                                }, origin: event.origin
                            })

                            // don't call saveQuizAnswer message when generating messages for chat module
                            // as it will change the lastQuiestionid in response and user might not be able to play it again on refresh
                            return;
                        }

                        //Separate audio save call from saveQuizAnswer
                        if (saveAudioAnswer) {
                            const { mediaFile, questionId } = gameData;
                            const mediaId = await uploadAudioAnswer({ questionId, mediaFile, linkId });
                            GC.sendMessageToGame({
                                message: {
                                    mediaId,
                                    status: EVENT_STATUS.SEND_GAME_AUDIO_SAVE_RESPONSE,
                                }, origin: event.origin
                            })
                            return;
                        }

                        // Separate MCQ save call
                        // Send event back to game -> To advance to next question/section/game
                        const { isMCQ, isChat = false } = gameData;
                        delete gameData?.isMCQ;
                        delete gameData?.isChat
                        const saveAnswerResponse = await saveQuizAnswer(linkId, gameData);
                        if (isChat && saveAnswerResponse) {
                            GC.sendMessageToGame({
                                message: {
                                    status: EVENT_STATUS.SEND_CHAT_SAVE_RESPONSE,
                                    questionId: gameData?.questionId ?? "",
                                },
                                origin: event.origin
                            })
                        } else if (isMCQ && saveAnswerResponse) {
                            GC.sendMessageToGame({
                                message: {
                                    status: EVENT_STATUS.SEND_MCQ_SAVE_RESPONSE,
                                },
                                origin: event.origin
                            });
                        }

                    } else {
                        const gameData = {
                            gameId: currentGame.id,
                            gameName: currentGame.name,
                            gameLevelData: event.data.gameLevelData,
                        };
                        await saveGameLevelData(linkId, gameData);
                    }
                    break;
                }
                case EVENT_STATUS.GAME_END: {
                    const { gameId, type } = event.data;
                    if (type === GAME_TYPE.QUIZ) {
                        // marking quiz complete
                        const { timeSpent } = event.data;
                        await markQuizComplete(gameId, { linkId, timeSpent });
                    }

                    if (gameId === currentGame.id) {
                        const updatedGameIds = playedGamesIds.includes(gameId)
                            ? playedGamesIds
                            : [...playedGamesIds, gameId];
                        initGame(updatedGameIds);
                    }
                    break;
                }

                /**
                 * Game window blur event is fired when a different element is brought to focus.
                 *  1. If this element is part of GC, then it is set as activeElement.
                 *  2. Else if user has interacted outside the browser page, then the activeElement is set 
                 *     as the element that is at the top of the HTML tree of that window, in this case 
                 *     it is game <iframe> (#game-frame). Hence, user should be disqualified.
                 */
                case EVENT_STATUS.GAME_BLURRED: {
                    const currentlyFocussedElement = document.activeElement
                    if (currentlyFocussedElement.id === "game-frame") {
                        disqualifyFullScreen()
                    }
                    break
                }
                default:
                    console.log('default event ::', event);
            }
        };
        window.addEventListener('message', messageEventHandler);

        // Remove event listener with previous gameData
        return () => window.removeEventListener('message', messageEventHandler);
    }, [initGame, linkId, playedGamesIds, currentGame, allowedOrigins]);
}
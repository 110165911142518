import { useEffect, useRef, useState } from "react"
import { Button, Select } from "antd"
import { CompanyLogo } from "components/common"
import PermissionsModal from "components/dumb/PermissionsModal"
import { PERMISSION_MODAL_TYPES } from "constants/globalConstants"
import { getMediaStream } from "utils/helper"
import {
    CameraWhite,
    CheckCircle,
    CheckCircleFilled,
    CrossCircle,
    CrossCircleFilled,
    Dropdown,
    HeadphonesWhite,
    TimerWhite,
    VideoCamera,
    WarningFilled
} from "assets/icons"
import "./styles.scss"

const { CAM_OCCUPIED, NO_CAM, } = PERMISSION_MODAL_TYPES;
let intervalId

const DeviceTest = () => {
    const [startTesting, setStartTesting] = useState(false)
    const [startRecording, setStartRecording] = useState(false)
    const [recordingTimeLeft, setRecordingTimeLeft] = useState(10)
    const [submitRecording, setSubmitRecording] = useState(false)
    const [isVideoApproved, setIsVideoApproved] = useState(null)
    const [isAudioApproved, setIsAudioApproved] = useState(null)
    const [videoDeviceId, setVideoDeviceId] = useState(null)
    const [audioDeviceId, setAudioDeviceId] = useState(null)
    const [mediaDevices, setMediaDevices] = useState([])
    const [toastMessage, setToastMessage] = useState(null)
    const [recordedVideoSrc, setRecordedVideoSrc] = useState(null)
    const [isPermissionModalVisible, setIsPermissionModalVisible] = useState(false)
    const [recorder, setRecorder] = useState(null)

    const liveVideoRef = useRef()

    const mimeType = MediaRecorder.isTypeSupported("video/webm") ? "video/webm" : "video/mp4"

    useEffect(() => {
            checkCameraAndMicPermission()
    }, [])

    useEffect(() => {
        if(startTesting) {
            navigator.mediaDevices.enumerateDevices().then(handleDevices)
        }
    }, [startTesting])

    /**
     * Initialise recorder even if device IDs are empty strings as those are valid IDs.
     */
     useEffect(() => {
        if (videoDeviceId !== null && audioDeviceId !== null) {
            initialiseRecorder(videoDeviceId, audioDeviceId)
        }
    }, [videoDeviceId, audioDeviceId])

    useEffect(() => {
        if (startRecording) {
            startAudioVideoRecording()
        }
    }, [startRecording])

    useEffect(() => {
        recorder?.addEventListener("dataavailable", handleDataAvailable)
        return () => recorder?.removeEventListener("dataavailable", handleDataAvailable)
    }, [recorder])

    useEffect(() => {
        if (recordingTimeLeft === 0) {
            recorder.stop()
            clearInterval(intervalId)
        }
    }, [recordingTimeLeft])

    useEffect(() => {
        if (isVideoApproved === false && isAudioApproved === false) {
            setToastMessage("There is some issue with your mic or camera, try using a different device or try recording the video again.")
        } else if(isVideoApproved === false) {
            setToastMessage("There is some issue with your camera, try using a different device or try recording the video again.")
        } else if(isAudioApproved === false) {
            setToastMessage("There is some issue with your mic, try using a different device or try recording the video again.")
        } else if (isVideoApproved === true && isAudioApproved === true) {
            setToastMessage("Done")
        }
    }, [isVideoApproved, isAudioApproved])

    const checkCameraAndMicPermission = async () => {
        const { isAllowed, failureReason } = await getMediaStream()
        if (!isAllowed) {
            if (failureReason?.toLowerCase()?.includes("could not start video source")) {
                setIsPermissionModalVisible(CAM_OCCUPIED)
            } else if(failureReason?.toLowerCase()?.includes("requested device not found")) {
                setIsPermissionModalVisible(NO_CAM)
            } else {
                setIsPermissionModalVisible(true)
            }
        }
    }

    const handleDevices = (mediaDevices) => {
        const videoMediaDevices = mediaDevices.filter(({ kind, label }) => kind === 'videoinput' && !label.toLowerCase().includes('back'))
        const audioMediaDevices = mediaDevices.filter(({ kind }) => kind === 'audioinput')
        setMediaDevices(mediaDevices)
        setVideoDeviceId(videoMediaDevices[0].deviceId ?? null)
        setAudioDeviceId(audioMediaDevices[0].deviceId ?? null)
    }

    const handleDataAvailable = (event) => {
        const videoSrc = URL.createObjectURL(event.data)
        setRecordedVideoSrc(videoSrc)
    }

    const startAudioVideoRecording = async () => {
        recorder.start()
        intervalId = setInterval(() => {
            setRecordingTimeLeft(timeLeft => timeLeft - 1)
        }, 1000)
    }

    const initialiseRecorder = async (videoDeviceId, audioDeviceId) => {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: {
                deviceId: videoDeviceId
            },
            audio: {
                deviceId: audioDeviceId
            }
        })
        liveVideoRef.current.srcObject = stream
        const mediaRecorder = new MediaRecorder(stream, { mimeType })
        setRecorder(mediaRecorder)
    }

    const handleRecordAgain = () => {
        setStartTesting(true)
        initialiseRecorder(videoDeviceId, audioDeviceId)
        setStartRecording(false)
        setSubmitRecording(false)
        setToastMessage(null)
        setIsVideoApproved(null)
        setIsAudioApproved(null)
    }

    return (
        <div className="device-test-pg color-primary flex flex-col justify-start">
            <CompanyLogo />
            <section className={`flex justify-between items-start mt-96`}>
                <article>
                    <p className="color-primary weight-600 heading size-55">
                        Test your system settings
                    </p>
                    <p className="color-primary opacity-70 size-18 weight-600 subheading font-ibm">
                        Hello! The assessment requires a working camera, microphone and speaker. Please test your system for a smooth assessment experience.
                    </p>
                    <ol className="mt-64 pl-20">
                        <li className="mb-12">
                            <p className="font-ibm size-16 weight-700 list-item-heading">
                                Test Your Setup
                            </p>
                            <p className="font-ibm size-16 weight-400 opacity-70 list-item-subheading">
                                Ensure your camera and microphone are working by using the ’Start Testing’ button.
                            </p>
                        </li>
                        <li className="mb-12">
                            <p className="font-ibm size-16 weight-700 list-item-heading">
                                Use the Same Device
                            </p>
                            <p className="font-ibm size-16 weight-400 opacity-70 list-item-subheading">
                                If your system check passes, continue with this device for the assessment.
                            </p>
                        </li>
                        <li>
                            <p className="font-ibm size-16 weight-700 list-item-heading">
                                Ensure Reliable Internet
                            </p>
                            <p className="font-ibm size-16 weight-400 opacity-70 list-item-subheading">
                                Confirm your internet connection is stable throughout the assessment.
                            </p>
                        </li>
                    </ol>
                </article>
                {
                    startTesting
                        ? <article className={`testing-div flex flex-col items-center ${submitRecording ? "relative testing-review-up" : startTesting ? "relative testing-up" : ""}`}>
                            {
                                !submitRecording
                                    ? <>
                                        <p className="font-ibm opacity-70 size-20 weight-400 heading align-center fade-in">
                                            Record a 10 sec video of yourself saying :
                                        </p>
                                        <p className="font-ibm size-20 weight-600 subheading align-center fade-in">
                                            "I'm testing my camera and microphone"
                                        </p>
                                    </> : <></>
                            }
                            {
                                submitRecording
                                    ? <video
                                        id="recorded"
                                        controls
                                        className="br-20 mb-20 fade-in"
                                    >
                                        <source src={recordedVideoSrc} />
                                    </video>
                                    : <div className="video-container mt-20 mb-20 relative fade-in">
                                        <video
                                            id="live"
                                            ref={liveVideoRef}
                                            autoPlay
                                            playsInline
                                            className="absolute br-20"
                                            muted
                                        />
                                    </div>
                            }
                            {
                                submitRecording
                                    ? <div className="recording-review-container fade-in">
                                        <p className="font-ibm review-heading size-20 weight-600 color-primary">
                                            Play the video recording &amp; mark the following Yes/No.
                                        </p>
                                        <div className="flex items-center justify-between mt-20">
                                            <div className="flex items-center">
                                                <span className="flex items-center mr-4">
                                                    <CameraWhite />
                                                </span>
                                                <p className="font-ibm size-20 weight-400 color-primary review-ques">
                                                    Do you see yourself in the video?
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <span
                                                    className="flex items-center mr-4 cursor-pointer"
                                                    onClick={() => setIsVideoApproved(true)}
                                                >
                                                    {
                                                        isVideoApproved === true
                                                            ? <CheckCircleFilled />
                                                            : <CheckCircle />
                                                    }
                                                </span>
                                                <span
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() => setIsVideoApproved(false)}
                                                >
                                                    {
                                                        isVideoApproved === false
                                                            ? <CrossCircleFilled />
                                                            : <CrossCircle />
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="review-divider-line" />
                                        <div className="flex items-center justify-between mt-10">
                                            <div className="flex items-center">
                                                <span className="flex items-center mr-4">
                                                    <HeadphonesWhite />
                                                </span>
                                                <p className="font-ibm size-20 weight-400 color-primary review-ques">
                                                    Were you able to listen your audio?
                                                </p>
                                            </div>
                                            <div className="flex items-center">
                                                <span
                                                    className="flex items-center mr-4 cursor-pointer"
                                                    onClick={() => setIsAudioApproved(true)}
                                                >
                                                    {
                                                        isAudioApproved === true
                                                            ? <CheckCircleFilled />
                                                            : <CheckCircle />
                                                    }
                                                </span>
                                                <span
                                                    className="flex items-center cursor-pointer"
                                                    onClick={() => setIsAudioApproved(false)}
                                                >
                                                    {
                                                        isAudioApproved === false
                                                            ? <CrossCircleFilled />
                                                            : <CrossCircle />
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    : startRecording
                                        ? <div className="flex items-center justify-between recording-info-container fade-in">
                                            <div className="flex flex-col">
                                                <div className="flex justify-between items-center mb-4">
                                                    <div className="flex items-center">
                                                        <div className="recording-icon mr-4" />
                                                        <p className="font-ibm size-16 weight-400 opacity-80">Recording</p>
                                                    </div>
                                                    <div className="flex items-center">
                                                        <span className="mr-4 flex items-center">
                                                            <TimerWhite />
                                                        </span>
                                                        <p className="size-16 weight-400 opacity-80">
                                                            {`00:${recordingTimeLeft < 10 ? `0${recordingTimeLeft}` : `${recordingTimeLeft}`}`}
                                                        </p>
                                                    </div>
                                                </div>
                                                <TimeProgressBar timeCovered={10 - recordingTimeLeft} />
                                            </div>
                                            <Button
                                                disabled={recordingTimeLeft > 0}
                                                className="size-14 weight-600 pl-20 pr-20 br-20 flex justify-center"
                                                type="primary"
                                                htmlType="submit"
                                                onClick={() => {
                                                    setStartTesting(true)
                                                    setStartRecording(false)
                                                    setSubmitRecording(true)
                                                    setRecordingTimeLeft(10)
                                                }}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        : <>
                                            {
                                                mediaDevices.length > 0
                                                    ? <div className="flex items-center mb-20 fade-in dropdowns-container">
                                                        <div className="flex items-center video-dropdown-container">
                                                            <CameraWhite />
                                                            <Select
                                                                className="size-14 max-width-200 video-select mr-20 ml-4"
                                                                suffixIcon={<Dropdown />}
                                                                value={videoDeviceId}
                                                                options={mediaDevices
                                                                    .filter(({ kind, label }) => kind === 'videoinput' && !label.toLowerCase().includes('back'))
                                                                    .map(({ label, deviceId }) => ({
                                                                        label: label.length > 0 ? label : "Default",   
                                                                        value: deviceId,
                                                                    }))}
                                                                onChange={(device) => setVideoDeviceId(device)}
                                                            />
                                                        </div>
                                                        <div className="flex items-center">
                                                            <HeadphonesWhite />
                                                            <Select
                                                                className="size-14 max-width-200 ml-4"
                                                                suffixIcon={<Dropdown />}
                                                                value={audioDeviceId}
                                                                options={mediaDevices
                                                                    .filter(({ kind }) => kind === 'audioinput')
                                                                    .map(({ label, deviceId }) => ({
                                                                        label: label.length > 0 ? label : "Default",
                                                                        value: deviceId,
                                                                    }))}
                                                                onChange={(device) => setAudioDeviceId(device)}
                                                            />
                                                        </div>
                                                    </div> : <></>
                                            }
                                            <Button
                                                disabled={!recorder || videoDeviceId === null || audioDeviceId === null}
                                                className={`size-14 weight-600 pl-20 pr-20 br-20 flex justify-center`}
                                                type="primary"
                                                htmlType="submit"
                                                onClick={() => {
                                                    setStartRecording(true)
                                                    setSubmitRecording(false)
                                                }}
                                            >
                                                Record Now
                                            </Button>
                                        </>
                            }
                            {
                                toastMessage?.startsWith("There")
                                    ? <div className="error-msg-container flex items-center justify-between br-10 pt-13 pr-15 pb-13 pl-15 mt-20 fade-in">
                                        <div className="flex items-center">
                                            <span className="flex items-center mr-4">
                                                <WarningFilled />
                                            </span>
                                            <p className="font-ibm size-14 weight-400 color-primary error-txt">{toastMessage}</p>
                                        </div>
                                        <Button
                                            className="size-14 weight-600 pl-20 pr-20 br-20 flex justify-center"
                                            type="primary"
                                            htmlType="submit"
                                            onClick={handleRecordAgain}
                                        >
                                            Try Again
                                        </Button>
                                    </div>
                                    : toastMessage?.startsWith("Done")
                                        ? <Button
                                            className="size-14 weight-600 pl-20 pr-20 br-20 flex justify-center mt-20 fade-in"
                                            type="primary"
                                            htmlType="submit"
                                            onClick={handleRecordAgain}
                                        >
                                            Record Again
                                        </Button> : <></>
                            }
                        </article>
                        : <div className="flex justify-center start-test-container">
                            <article className="pt-36 pb-36 flex flex-col items-center br-20 start-test-div">
                                <p className="font-ibm size-22 weight-600 align-center">
                                    Test your device
                                </p>
                                <div className="flex items-center justify-center p-20 mt-20 mb-47">
                                    <VideoCamera />
                                </div>
                                <p className="font-ibm size-16 opacity-70 weight-400 align-center">
                                    Please record a 10 sec video to test your device camera, mic and speaker.
                                </p>
                                <Button
                                    className="size-14 weight-600 pl-20 pr-20 br-20 flex justify-center"
                                    type="primary"
                                    htmlType="submit"
                                    onClick={() => {
                                        setStartTesting(true)
                                        setStartRecording(false)
                                        setSubmitRecording(false)
                                    }}
                                >
                                    Start Testing
                                </Button>
                            </article>
                        </div>
                }
            </section>
            <PermissionsModal
                visible={isPermissionModalVisible}
                type={
                    typeof(isPermissionModalVisible) === "string" 
                        ? isPermissionModalVisible 
                        : PERMISSION_MODAL_TYPES.CAMERA_MIC
                }
            />
        </div>
    )
}

export default DeviceTest

const TimeProgressBar = ({ timeCovered }) => {
    return (
        <div className="flex items-center relative">
            {
                Array.from({ length: 10 }, (_, index) => index)
                    .map(val => {
                        return (
                            <div className={`mr-5 progress-bar-item ${(val > timeCovered) ? "opacity-30" : ""}`} />
                        )
                    })
            }
        </div>
    )
}
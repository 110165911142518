import { useEffect } from "react"
import { GC } from "utils/postMessage"
import { PAUSE_TIMER_EVENT, RESUME_TIMER_EVENT } from "constants/helperModalConstants"

export const usePauseResumeTimer = (currentGame) => {

    const handlePauseResumeTimer = (e) => {
        GC.sendMessageToGame({
            message: {
                status: e.type
            },
            origin: currentGame.url
        })
    }

    useEffect(() => {
        window.addEventListener(PAUSE_TIMER_EVENT, handlePauseResumeTimer)
        window.addEventListener(RESUME_TIMER_EVENT, handlePauseResumeTimer)
        return () => {
            window.removeEventListener(PAUSE_TIMER_EVENT, handlePauseResumeTimer)
            window.removeEventListener(RESUME_TIMER_EVENT, handlePauseResumeTimer)
        }
    }, [currentGame])
}
import CandidateForm from 'views/CandidateForm';
import GameLoader from 'views/GameLoader';
import RulesScreen from 'views/RulesScreen';

export const PROTECTED_ROUTES = [
  {
    path: '/form/:id',
    component: CandidateForm,
  },
  {
    path: '/rules/:id',
    component: RulesScreen,
  },
  {
    path: '/games/:id',
    component: GameLoader,
  },
];


import '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-converter';
import '@tensorflow/tfjs-backend-webgl';
import * as blazeface from '@tensorflow-models/blazeface';
import { createImgHTML } from './helper';

export const detectFacesInImage = async (imgSrc) => {
  let isImageCritical = false;
  let criticalReason = '';

  try {
    const currentImg = createImgHTML(imgSrc);
    const model = await blazeface.load()
    const faceDescriptions = await model.estimateFaces(currentImg, false);

    if (faceDescriptions.length === 0) {
      // No face detected mark as critical
      isImageCritical = true;
      criticalReason = 'Make sure you are in a well-lit room with a plain background and your face is properly centered in above frame';
    } else if (faceDescriptions.length > 1) {
      // More than one face detected mark as critical
      isImageCritical = true;
      criticalReason = 'Multiple persons detected';
    } else if (faceDescriptions.length === 1) {
      // if face captured is partial or at an angle
      if (faceDescriptions[0].probability[0] < 0.9) {
        isImageCritical = true;
        criticalReason = 'No person detected';
      }
      // else do nothing
    }
  } catch (e) {
    console.log(e);
  } finally {
    return {
      isImageCritical,
      criticalReason,
    };
  }
};

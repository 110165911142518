import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FeedbackSection from 'components/AssessmentEndScreen/FeedbackSection';
import ThankYou from 'components/AssessmentEndScreen/ThankYou';
import { useIsMobileDevice } from 'hooks';
import { storage } from 'services/config/storage';
import { disableFullScreen } from 'utils/helper';
import React from 'react';
import { DISQUALIFY_REASONS } from 'constants/globalConstants';
import './style.scss';
import { useGameplayConfig } from 'store';

const AssessmentEndScreen = (props) => {
  const showGameplayOnly = storage.get.showGameplayOnly();
  const feedbackAllowed =
    !showGameplayOnly || storage.get.feedbackAllowed();

  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)
  const [feedbackHidden, setFeedbackHidden] = useState(false)
  const { state } = useLocation()
  const isMobile = useIsMobileDevice()
  const { userId } = useParams()
  const { isFeedbackVisible, isFullScreen, isFetchingConfig } = useGameplayConfig()

  /**
   * Remove feedback components from the DOM after translate animation
   */
  useEffect(() => {
    if (feedbackSubmitted) {
      setTimeout(() => {
        setFeedbackHidden(true)
      }, 450)
    }
  }, [feedbackSubmitted])

  /**
   * If feedback is not visible, then remove all LS
   */
  useEffect(() => {
    if (!isFeedbackVisible) {
      storage.destroy.all();
    } else if (isFeedbackVisible) {
      const isFeedbackSubmittedLS = storage.get.isFeedbackSubmitted(userId)
      if (isFeedbackSubmittedLS) {
        setFeedbackSubmitted(true)
      }
    }
    if (isFullScreen) {
      disableFullScreen()
    }
  }, [isFeedbackVisible, isFetchingConfig, isFullScreen, userId])

  /**
   * If user is disqualified, only then remove LS keys except ones related to feedback and disqualification
   */
  useEffect(() => {
    if (state?.disqualifyReason?.length > 0) {
      storage.set.userDisqualified()
      storage.destroy.allExceptFeedbackSubmittedAndUserDisqualified(userId)
    }
  }, [state])

  useEffect(() => {
    if (!feedbackAllowed) {
      setFeedbackSubmitted(true)
      setFeedbackHidden(true)
    }
  }, [feedbackAllowed])

  if (isFetchingConfig) {
    return (
      <section className="overflowY-hidden overflowX-hidden h-full-page bg-color-primary flex flex-col justify-center items-center">
        <span className="loader"></span>
      </section>
    );
  }

  return (
    <section className="end-screen-pg h-full-page bg-color-primary flex items-center justify-center color-primary">
      {feedbackSubmitted && feedbackHidden ? (
        <ThankYou
          message={
            showGameplayOnly ? 'You aced this adventure like a boss!' : null
          }
        />
      ) : (
        <></>
      )}
      {
        !feedbackHidden ?
          <div className={`message-container ${feedbackSubmitted ? "feedback-translate" : ""} flex flex-col ${isFeedbackVisible ? "items-start" : "items-center"} justify-center`}>
            <span className='heading-emoji'>
              {
                state?.disqualifyReason?.length > 0 ? "⚠️" : "👏"
              }
            </span>
            <span className="heading mb-18">
              {
                state?.disqualifyReason?.length > 0 ? "Disqualified" : "Congratulations"
              }
            </span>
            <span className={`sub-heading mb-5 font-medium ${isMobile ? "text-center" : isFeedbackVisible ? "align-left" : "align-center"}`}>
              {
                state?.disqualifyReason?.length > 0 ? `You have been disqualified from the assessment for ${state?.disqualifyReason === DISQUALIFY_REASONS.FULLSCREEN ? "leaving fullscreen" : "switching tabs too many times"} despite warnings!` : `You aced this adventure like a boss! We shall get in touch with you soon!${isFeedbackVisible ? " Tell us about your experience" : ""}`
              }
            </span>
          </div> : <></>
      }
      {
        isFeedbackVisible && !feedbackHidden
          ? <FeedbackSection feedbackSubmitted={feedbackSubmitted} setFeedbackSubmitted={setFeedbackSubmitted} />
          : <></>
      }
    </section>
  );
};

export default AssessmentEndScreen;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from 'routes/AppRouter';

// sentry imports 
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

function App() {

  return (
    <Sentry.ErrorBoundary
      onError={(err) => {
        console.log("Fallback UI showing. Error - ", err)
        Sentry.captureException(`"Fallback UI error - "${err}`)
      }}
      fallback={<p style={{color: "#fff"}}>An error has occurred</p>}
    >
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
}

export default App;

const { storage } = require("services/config/storage");

const CompanyLogo = () => {
    const isOrgWhiteLabelled = storage.get.orgInfo()?.organizationConfig?.moduleFlags?.whitelabel;
    const companyLogo = storage.get.companiesLogos()

    return (
        <div>
            {companyLogo ? (
                <div className="mobile-company-logo flex flex-col items-start">
                    <img src={companyLogo} />
                    {
                        !isOrgWhiteLabelled &&
                        <p className="mt-4">
                            powered by <span>unberry</span>
                        </p>
                    }
                </div>
            ) : (
                <>
                {
                    !isOrgWhiteLabelled && <p className="color-primary font-medium size-34 mb-0">unberry.</p>
                }
                </>
            )}
        </div>
    )
}

export default CompanyLogo
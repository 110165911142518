import { useIsMobileDevice } from "hooks";
import UnberryModal from "../UnberryModal";
import Carousel, { CarouselItem } from "../Carousel";
import './style.scss';
import CameraPermissionTabImageTwo from '../../../assets/img/permission/cam-permission-tab-image-2.png';
import CameraPermissionTabImageThird from '../../../assets/img/permission/cam-permission-tab-image-3.png';
import CameraPermissionCarouselOne from '../../../assets/img/permission/cam-permission-carousel-1.png';
import CameraPermissionCarouselTwo from '../../../assets/img/permission/cam-permission-carousel-2.png';
import CameraPermissionCarouselThree from '../../../assets/img/permission/cam-permission-carousel-3.png';
import CamMicPermissionTabImageOne from '../../../assets/img/permission/cam-mic-permission-tab-image-1.png';
import CamMicPermissionTabImageTwo from '../../../assets/img/permission/cam-mic-permission-tab-image-2.png';
import CamMicPermissionTabImageThird from '../../../assets/img/permission/cam-mic-permission-tab-image-3.png';
import CamMicPermissionCarouselOne from '../../../assets/img/permission/cam-mic-permission-carousel-1.jpg';
import CamMicPermissionCarouselTwo from '../../../assets/img/permission/cam-mic-permission-carousel-2.jpg';
import CamMicPermissionCarouselThree from '../../../assets/img/permission/cam-mic-permission-carousel-3.jpg';
import IOSCamMicPermissionCarouselThree from '../../../assets/img/permission/ios-cam-mic-permission-carousel-3.jpg';
import AndroidCamPermissionCarouselThree from '../../../assets/img/permission/android-cam-permission-carousel-3.jpg';

import { PERMISSION_MODAL_TYPES } from "constants/globalConstants";

const { CAMERA_FEED, NO_INTERNET, CAM_OCCUPIED, NO_CAM } = PERMISSION_MODAL_TYPES;

const isUserAgentIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent)

const cameraTabData = [
  {
    tabText: 'Click on the settings icon in address bar',
    imgSrc: CamMicPermissionTabImageOne,
    carouselText: isUserAgentIOS()
      ? 'Open settings on your phone'
      : 'Open browser settings on your phone',
    carouselImgSrc: isUserAgentIOS()
      ? CameraPermissionCarouselOne
      : CamMicPermissionCarouselOne
  },
  {
    tabText: 'Enable permissions for camera',
    imgSrc: CameraPermissionTabImageTwo,
    carouselText: isUserAgentIOS()
      ? 'Scroll down to find your browser'
      : 'Go to permissions menu',
    carouselImgSrc: isUserAgentIOS()
      ? CameraPermissionCarouselTwo
      : CamMicPermissionCarouselTwo
  },
  {
    tabText: 'Refresh your browser to save changes',
    imgSrc: CameraPermissionTabImageThird,
    carouselText: isUserAgentIOS()
      ? 'Turn the camera toggle to ON'
      : 'Allow camera and refresh the browser to save changes',
    carouselImgSrc: isUserAgentIOS()
      ? CameraPermissionCarouselThree
      : AndroidCamPermissionCarouselThree
  }
];

const camMicTabData = [
  {
    tabText: 'Click on the setting icon in address bar',
    imgSrc: CamMicPermissionTabImageOne,
    carouselText: isUserAgentIOS()
      ? 'Open settings on your phone'
      : 'Open browser settings on your phone',
    carouselImgSrc: isUserAgentIOS()
      ? CameraPermissionCarouselOne
      : CamMicPermissionCarouselOne
  },
  {
    tabText: 'Enable permissions for camera and mic',
    imgSrc: CamMicPermissionTabImageTwo,
    carouselText: isUserAgentIOS()
      ? 'Scroll down to find your browser'
      : 'Allow camera and mic in permissions menu',
    carouselImgSrc: isUserAgentIOS()
      ? CameraPermissionCarouselTwo
      : CamMicPermissionCarouselTwo
  },
  {
    tabText: 'Refresh your browser to save changes',
    imgSrc: CamMicPermissionTabImageThird,
    carouselText: isUserAgentIOS()
      ? 'Turn the camera and mic toggles to ON'
      : 'Allow for both and refresh your browser to save changes',
    carouselImgSrc: isUserAgentIOS()
      ? IOSCamMicPermissionCarouselThree
      : CamMicPermissionCarouselThree
  }
];

const CarouselComponent = ({ type = "camera" }) => {
  const tabData = type === "camera" ? cameraTabData : camMicTabData

  return (
    <Carousel>
      {
        tabData.map((tab, index) => {
          return (
            <CarouselItem>
              <div className={`tab flex flex-col items-center justify-center`}>
                <div className="tab-description flex items-center justify-center mb-20">
                  <div className="tab-number-carousel">
                    <span>{index + 1}</span>
                  </div>
                  <p className="tab-text-carousel">{tab.carouselText}</p>
                </div>
                <div className="tab-image-carousel flex items-center justify-center">
                  <img src={tab.carouselImgSrc} alt="permission-access-microphone" />
                </div>
              </div>
            </CarouselItem>
          )
        })
      }
    </Carousel>
  );
};

const PermissionTabComponent = ({ tabText, tabNumber, imgSrc }) => {

  return (
    <div className={`tab pl-20 pr-20
      flex flex-col items-center justify-start
        ${tabNumber === 2 ? 'border' : ''}
      `}>
      <div className="tab-description flex items-center justify-center mb-20">
        <div className="tab-number">
          <span>{tabNumber}</span>
        </div>
        <p className="tab-text">{tabText}</p>
      </div>
      <div className="tab-image">
        <img src={imgSrc} alt="permission-access-microphone-1" />
      </div>
    </div>
  )
};

const PermissionsModal = ({ visible, type = "camera" }) => {
  const isMobile = useIsMobileDevice();
  const tabData = type === "camera" ? cameraTabData : camMicTabData;
  const noCarouselType = [NO_INTERNET, CAMERA_FEED, CAM_OCCUPIED, NO_CAM].includes(type);
  const modalWidth = isMobile ? "80%" : noCarouselType ? "50%" : "80%";

  return (
    <UnberryModal
      visible={visible}
      footer={false}
      className={"permission-modal"}
      width={modalWidth}
      closable={false}
      keyboard={false}
      maskStyle={{ 
        backdropFilter: "blur(4px)" 
      }}
    >
      <div className={`permission-modal-body ${isMobile ? "mt-40" : ""} mb-40`}>
        <div className="description flex flex-col items-center justify-center mb-40">
          <h1 className={`${isMobile ? "heading-mobile size-28" : "size-40"}`}>
            {
              type === NO_INTERNET
                ? "No internet connection!"
                : type === CAMERA_FEED
                  ? "Permission not detected"
                  : type === CAM_OCCUPIED
                    ? "Unable to access Camera"
                    : type === NO_CAM
                      ? "Camera not found"
                      : `Allow us to access your ${type}`
            }
          </h1>
          <p className={`${isMobile ? "heading-text size-14" : "size-16"}`}>
            {
              type === NO_INTERNET
                ? "Please switch to a better network, or we might lose some important data. When internet is back, please refresh the page to continue further."
                : type === CAMERA_FEED
                  ? "We are unable to access your mic/camera. Please refresh your screen in order to continue."
                  : type === CAM_OCCUPIED
                    ? "Please close any other application that might be using camera and refresh this page."
                    : type === NO_CAM
                      ? "There seems to be some issue with your device’s camera. Please switch to some other device to take the assessment."
                      : `This is a proctored session, we need the permission for your ${type} to start your gameplay.`
            }
          </p>
        </div>
        {
          !noCarouselType
            ? <div className="permission-tabs">
              {
                isMobile ? <CarouselComponent type={type} /> :
                  tabData.map((tab, index) => {
                    return (
                      <PermissionTabComponent
                        tabText={tab.tabText}
                        tabNumber={index + 1}
                        imgSrc={tab.imgSrc}
                      />
                    )
                  })
              }
            </div> : <></>
        }
      </div>
    </UnberryModal>
  )
};

export default PermissionsModal;
import { Bars } from 'assets/icons';
import './style.scss';
import { storage } from 'services/config/storage';

const HeroPageTemplate = ({
  heading,
  content,
  companyLogo,
  hideLogo = false,
  children,
  headingSize = 40,
  isMobile = false,
  hasGodrejPosition = false
}) => {

  const isOrgWhiteLabelled = storage.get.orgInfo()?.organizationConfig?.moduleFlags?.whitelabel;
  
  return (
    <div className={`hero-section flex h-full ${hasGodrejPosition ? "godrej-style" : ""}`}>
      <div>
        {!hideLogo &&
          (companyLogo ? (
            <div className={`company-logo flex flex-col items-start`}>
              <img src={companyLogo} />
              {
                !isOrgWhiteLabelled &&
                <p className="mt-4">
                  powered by <span>unberry</span>
                </p>
              }
            </div>
          ) : (
            <>
              {
                !isOrgWhiteLabelled && <h1 className="logo">unberry</h1>
              }
            </>
          ))}
        {/* <div className="company-logo">
            <img src={unberryLogo} alt="" />
        </div> */}
        <div className="greetings-form-wrapper">
          <div className="greetings">
            <h1 className={`${!isMobile ? `heading-${headingSize}` : ""}`}>{heading}</h1>
            <p className={""}>{content}</p>
          </div>

          <div>{children}</div>
        </div>
      </div>
      <div>
        <span className={`bars-bg`}>
          <Bars />
        </span>
      </div>
    </div>
  );
};

export default HeroPageTemplate;

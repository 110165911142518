import { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, InputNumber, Radio, Select } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import { CompanyLogo, DatePicker } from "components/common";
import PhoneInput from "components/smart/PhoneInput";
import { useGameplayConfig } from "store";
import candidateFormApi from "services/apis/form";
import mediaApi from "services/apis/media";
import { COUNTRY_DATA, DEFAULT_COUNTRY_DATA, GODREJ_SECTION_IDS } from "constants/globalConstants";
import { acceptedFileTypes, dropdownInputMode, dropdownInputOptions, formInputGroups, initialValues } from "./constants";
import { getDateBeforeNYears, validatePercentage, validateYear } from "utils/helper";
import { storage } from "services/config/storage";
import { FileUpload } from "assets/icons";
import "./styles.scss";
import { CustomTooltip } from "components/dumb/CustomTooltip";
import { InfoCircleOutlined } from "@ant-design/icons";

const CandidateForm = () => {
    const [formValues, setFormValues] = useState(initialValues);
    const [requiredFormInputs, setRequiredFormInputs] = useState([]);
    const [country, setCountry] = useState(DEFAULT_COUNTRY_DATA);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [site, setSite] = useState(undefined);
    const [siteOptions, setSiteOptions] = useState([]);
    const [candidateForm] = Form.useForm();
    const { positionId, customizableForm } = useGameplayConfig();
    const { id: linkId } = useParams();
    const history = useHistory();
    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        const fileType = file.type;
        const fileSize = file.size / (1024 * 1024);
        if (fileSize <= 10 && acceptedFileTypes.find(acceptedType => acceptedType === fileType)) {
            setFile(file);
            candidateForm.setFieldValue("resumeUpload", file);
            candidateForm.setFields([
                {
                    name: "resumeUpload",
                    errors: [''],
                },
            ]);
        } else {
            setFile(null);
            candidateForm.setFieldValue("resumeUpload", null);
            candidateForm.setFields([
                {
                    name: "resumeUpload",
                    errors: ['File is not valid'],
                },
            ]);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const hasGodrejPosition = GODREJ_SECTION_IDS.includes(positionId);
    const orgInfo = storage.get.orgInfo()

    /**
     * If candidate form is enabled for position, fetch the data and input configs.
     * If some data has been entered before, skip to rules page.
     * If form is not enabled, skip to rules page.
     * Dynamic country code from org config as per Neha.
     */
    useEffect(() => {
        (async () => {
            if (customizableForm) {
                if (positionId) {
                    try {
                        setLoading(true);
                        const countryCode = orgInfo?.organizationConfig?.countryCode ?? "91"
                        const country = COUNTRY_DATA?.find(cuntry => cuntry?.phone == countryCode)
                        setCountry(country)
                        const [formDataRes, formInputsRes] = await Promise.all([getFormData(), getFormInputs(positionId)]);
                        if (formDataRes.success) {
                            if (formDataRes.userData !== null) {
                                history.push(`/rules/${linkId}`);
                            }
                        }
                        if (formInputsRes.success) {
                            const { formConfigs } = formInputsRes;
                            let formInputsResObj = {};
                            let formInputsRequired = {};
                            formConfigs.forEach(inputConfig => {
                                formInputsResObj[inputConfig.keyName] = "";
                                formInputsRequired[inputConfig.keyName] = inputConfig.isRequired;
                            });
                            setFormValues(formInputsResObj);
                            setRequiredFormInputs(formInputsRequired);
                        }
                    } catch (err) {
                        console.error(err);
                    } finally {
                        setLoading(false);
                    }
                }
            } else {
                history.push(`/rules/${linkId}`);
            }
        })();
    }, [positionId, customizableForm]);

    /**
     * Get the form data that user had entered.
     */
    const getFormData = async () => {
        try {
            const formDataRes = await candidateFormApi.getCandidateFormData(linkId);
            return formDataRes;
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Get the list of input fields to be shown.
     * Setting form values using setFormValues and setting all the mandatory inputs using setRequiredFormInputs
     */
    const getFormInputs = async (positionId) => {
        try {
            const formInputsRes = await candidateFormApi.getCandidateFormInputs(positionId);
            return formInputsRes;
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Return the relevant input component based on type
     * @param {type} - type of input
     * @param {placeholder} - placeholder text
     * @param {input} - key name of the input value
     * @return {HTMLElement}
     */
    const getInputComponent = ({ type, placeholder, input, isInputMandatory, tooltipText }) => {
        const showTooltip = hasGodrejPosition && tooltipText;
        switch (type) {
            case "text":
                return !showTooltip ?
                    <Input
                        placeholder={placeholder}
                    /> : (
                        <div className="flex item-start relative">
                            <Input
                                placeholder={placeholder}
                            />
                            <CustomTooltip
                                title={tooltipText}
                            >
                                <InfoCircleOutlined style={{ fontSize: '24px', color: 'white', position: "relative", top: "14px", cursor: "pointer", marginLeft: "8px" }} />
                            </CustomTooltip>
                        </div>
                    );
            case "textarea":
                return <Input.TextArea
                    placeholder={placeholder}
                    rows={5}
                />;
            case "phone":
                return <PhoneInput
                    placeholder={placeholder}
                    className="flex items-center phone-input"
                    setValue={(value) => candidateForm.setFieldValue(input, value)}
                    country={country}
                    hasPhoneLogin={false}
                    setCountry={setCountry}
                />;
            case "file":
                return <><div
                    {...getRootProps()}
                    className={`p-30 br-10 flex flex-col items-center justify-center cursor-pointer file-upload ${false ? "file-error" : ""}`}
                >
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the file here ...</p> :
                            <>
                                <FileUpload />
                                <p className="size-14 color-primary m-0 mt-4">{placeholder}</p>
                                <p className="size-14 color-primary opacity-70 m-0">Max Size 10 MB</p>
                                {
                                    file && <p className="size-14 color-primary m-10 width-200 align-center">{file?.name}</p>
                                }
                            </>
                    }
                </div>
                </>;
            case "radio":
                return <div className="flex items-center">
                    <p className="size-14 weight-500 color-primary capitalize category-heading mb-0 mr-20">{placeholder}:</p>
                    <Radio.Group
                        onChange={(value) => candidateForm.setFieldValue(input, value)}
                    >
                        <Radio.Button value={"male"}>
                            Male
                        </Radio.Button>
                        <Radio.Button value={"female"}>
                            Female
                        </Radio.Button>
                        <Radio.Button value={"prefer not to say"}>
                            Prefer not to say
                        </Radio.Button>
                        <Radio.Button value={"others"}>
                            Others
                        </Radio.Button>
                    </Radio.Group>
                </div>;
            case "date":
                return <DatePicker
                    startingYear={Number(getDateBeforeNYears(50).getFullYear())}
                    endingYear={Number(getDateBeforeNYears(14).getFullYear())}
                    setFormFieldValue={(value) => {
                        candidateForm.setFieldsValue({
                            dob: value,
                        });
                    }}
                />;
            case "year":
                return <Input placeholder={placeholder} />;
            case "number":
                return <InputNumber
                    type="number"
                    min={0}
                    controls={false}
                    placeholder={placeholder}
                />;
            case "multi-select":
            case "single-select":
                return <>
                    <Select
                        {...(input === "site" && { value: site })}
                        mode={dropdownInputMode[input]}
                        placeholder={placeholder}
                        className={`location-select`}
                        popupClassName="candidateform-select-popup"
                        onChange={(value) => handleSelectChange({ input, value, isInputMandatory })}
                        options={(input === "site" ? siteOptions : dropdownInputOptions[input])?.map(option => ({ label: option, value: option }))}
                    />
                </>;
            default: return <></>;
        }
    };

    /**
     * Handles the change of all inputs using Select.
     * Reset site options and value on zone change.
     * @param {string} input - name of input.
     * @param {string} value - value of input.
     * @param {boolean} isInputMandatory
     */
    const handleSelectChange = ({ input, value, isInputMandatory }) => {
        candidateForm.setFieldValue(input, (typeof value === "string") ? value : value.join(","));
        if (input === "zoneName") {
            setSite(undefined);
            candidateForm.setFieldValue("site", undefined);
            setSiteOptions(dropdownInputOptions[`${value}-site`]);
        }
        if (input === "site") {
            setSite(value);
        }
        if (isInputMandatory) {
            const errorMsg = value.length > 0 ? "" : "This is required";
            candidateForm.setFields([
                {
                    name: input,
                    errors: [errorMsg],
                },
            ]);
        }
    };

    /**
     * Save the candidate form values and redirect to rules page.
     */
    const handleFormSubmit = async (formData) => {
        try {
            setSubmitting(true);
            if (file) {
                const filePayload = {
                    file,
                    fileName: `${new Date().getTime()}-${file.name}`,
                    type: file.type,
                    organizationId: storage.get.orgId()
                };
                try {
                    const fileRes = await mediaApi.uploadMedia(filePayload);
                    if (fileRes.success) {
                        formData.resumeUpload = fileRes.media.link;
                    }
                } catch (err) {
                    console.error(err);
                }
            } else if (requiredFormInputs.resumeUpload) return;
            if (formData.dob) {
                formData.dob = moment(formData.dob.toString()).format("DD-MM-YYYY");
            } if (formData.phone) {
                delete formData.phone
            }

            const response = await candidateFormApi.saveCandidateForm(linkId, formData);
            if (response.success) {
                history.push(`/rules/${linkId}`);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setSubmitting(false);
        }
    };

    /**
     * If all the inputs of a category are hidden, then don't render the category heading along with it's grid in DOM.
     * To understand the structure, refer to const formInputGroups in constants.js.
     */
    const isCategoryEmpty = (category) => {
        const updatedInputGroups = { ...formInputGroups };
        Object.keys(updatedInputGroups[category]).forEach(group => {
            Object.keys(updatedInputGroups[category][group]).forEach(input => {
                if (!Object.keys(formValues).find(formValue => formValue === input)) {
                    delete updatedInputGroups[category][group][input];
                }
            });
            if (Object.values(updatedInputGroups[category][group]).length === 0) delete updatedInputGroups[category][group];
        });
        if (Object.values(updatedInputGroups[category]).length === 0) {
            return true;
        }
        return false;
    };

    return (
        <div className="form-pg bg-color-primary color-primary p-30">
            <CompanyLogo />
            {
                loading
                    ? <section className="overflowY-hidden overflowX-hidden h-full-page bg-color-primary flex flex-col justify-center items-center">
                        <span className="loader"></span>
                    </section>
                    : <div className="form-container flex flex-col items-center mt-20">
                        <p className="size-40 weight-600 form-heading">
                            We just need a few more details
                        </p>
                        <Form
                            name="candidate-form"
                            form={candidateForm}
                            initialValues={formValues}
                            onFinish={handleFormSubmit}
                        > {
                                Object.keys(formInputGroups).map(category => isCategoryEmpty(category) ? <></>
                                    : (
                                        <div key={category} className="mb-40">
                                            <p className="size-14 weight-500 color-primary uppercase category-heading">
                                                {category}
                                            </p>
                                            {
                                                Object.keys(formInputGroups[category]).map(group => (
                                                    <div key={`${category}-${group}`} className="grid grid-cols-2 grid-gap-10 mb-10">
                                                        {
                                                            Object.keys(formInputGroups[category][group]).map(input => {
                                                                const type = formInputGroups[category][group][input]["type"];
                                                                const placeholder = formInputGroups[category][group][input]["placeholder"];
                                                                const isInputVisible = !!Object.keys(formValues).find(formValue => formValue === input);
                                                                const tooltipText = formInputGroups[category][group][input]["tooltipText"];
                                                                const isInputMandatory = requiredFormInputs[input];
                                                                return isInputVisible ? (
                                                                    <Form.Item
                                                                        key={input}
                                                                        name={input}
                                                                        rules={[
                                                                            {
                                                                                required: isInputMandatory,
                                                                                message: 'This is required',
                                                                            },
                                                                            {
                                                                                validator: (input === "tenthPercentage" || input === "twelfthPercentage" || input === "percentage")
                                                                                    ? validatePercentage
                                                                                    : type === "year"
                                                                                        ? validateYear
                                                                                        : () => Promise.resolve()
                                                                            },
                                                                        ]}
                                                                        className={(type === "radio" || type === "multi-select") ? "options-input" : hasGodrejPosition ? "godrej-custom" : ""} 
                                                                    >
                                                                        {
                                                                            getInputComponent({ type, input, placeholder, isInputMandatory, tooltipText })
                                                                        }
                                                                    </Form.Item>
                                                                ) : (<></>);
                                                            })
                                                        }
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    ))
                            }
                            <Form.Item>
                                <div className="submit-btn-container">
                                    <Button
                                        className="font-medium pl-20 pr-20 br-20 flex justify-center"
                                        type="primary"
                                        htmlType="submit"
                                        loading={submitting}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
            }
        </div>
    );
};

export default CandidateForm;

import React from 'react';
import styles from "./styles.module.scss";

export const CustomTooltip = ({
	title,
	children
}) => {
	return (
		<div className={styles.tooltip}>
			{children}
			<span className={styles.tooltip_text}>{title}</span>
		</div>
	);
};

import { useState, useCallback, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import { Button, Checkbox, Result, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import PermissionsModal from '../PermissionsModal';
import { useImgComparison } from 'hooks';
import { detectFacesInImage } from 'utils/faceDetection';
import { getMediaStream, getPrivacyPolicyLink } from 'utils/helper';
import { storage } from 'services/config/storage';
import { Camera, Dropdown } from 'assets/icons';
import { PAUSE_TIMER_EVENT } from 'constants/helperModalConstants';
import { MEDIA_DEVICE_ERRORS, PERMISSION_MODAL_TYPES } from 'constants/globalConstants';

const { CAMERA_FEED, CAM_OCCUPIED, NO_CAM } = PERMISSION_MODAL_TYPES

const WebcamCapture = ({
  initialCapture = false,
  isMobile = false,
  snapshotInterval = 60,
  imgSrc,
  setImgSrc,
  confirmPhoto,
  loading,
  linkId,
  consent,
  setConsent,
  isVideoProctored = false,
  pauseGameTimer = () => {} /* Call pauseGameTimer to update pauseGameTimerOnMount ref in GameLoader/index.js */
}) => {
  const webcamRef = useRef(null);

  const isChrome = /Chrome/.test(navigator.userAgent);
  const privacyPolicyLink = getPrivacyPolicyLink();

  const [webCamKey, setWebCamKey] = useState(0); // for refreshing WebCam component (see useEffect for more info)
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [deviceId, setDeviceId] = useState(storage.get.webCam());
  const [devices, setDevices] = useState(null);
  const [imageAnalysisData, setImageAnalysisData] = useState(null);

  const { proctorGameplayCapture } = useImgComparison({ linkId });

  const saveWebCamDevice = useCallback((deviceId) => {
    storage.set.webCam(deviceId);
    setDeviceId(deviceId);
  }, []);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const videoSources = mediaDevices.filter(
        ({ kind, label }) =>
          kind === 'videoinput' && !label.toLowerCase().includes('back')
      );
      setDevices(videoSources);

      if (!deviceId) {
        const [firstSource] = videoSources ?? [{}];
        saveWebCamDevice(firstSource.deviceId);
      }
    },
    [deviceId, saveWebCamDevice]
  );

  const capture = useCallback(() => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then(
        () => {
          const imageSrc = webcamRef.current.getScreenshot();
          setImgSrc(imageSrc);
          setShowPermissionModal(false);
          if(initialCapture) {
            proctorInitialCapture(imageSrc)
          } else {
            proctorGameplayCapture(imageSrc)
          }
        },
        (err) => {
          console.error('Error in camera permission', err);
          if (err?.toString()?.toLowerCase()?.includes(MEDIA_DEVICE_ERRORS.VIDEO_SOURCE)) {
            setShowPermissionModal(CAM_OCCUPIED)
          } else if(err?.toString()?.toLowerCase()?.includes(MEDIA_DEVICE_ERRORS.NO_DEVICE)) {
            setShowPermissionModal(NO_CAM)
          } else {
            setShowPermissionModal(true)
          }
          pauseGameTimer()
          // Toast('error', "Please ensure the camera permission is given at all times, otherwise it will impact your score. Please enable camera in order to move further.")
        }
      );
  }, [webcamRef, setImgSrc, initialCapture]);

  const onRetake = useCallback(() => {
    setImgSrc('');
    setImageAnalysisData(null);
    setConsent(false);
  }, []);

  const proctorInitialCapture = async (imageSrc) => {
    const data = await detectFacesInImage(imageSrc)
    setImageAnalysisData(data)
  }

  useEffect(() => {
    let intervalId;
    if (!initialCapture && webcamRef.current) {
      intervalId = setInterval(() => {
        capture();
      }, 1 * snapshotInterval * 1000);
    }

    return () => clearInterval(intervalId);
  }, [capture, initialCapture, snapshotInterval]);

  /**
   * For image proctoring, on rules and games screens, check the permission as soon as the page is rendered.
   * Video proctoring permissions are checked separately in GamesLoader.
   */
  useEffect(() => {
    (async () => {
      if (!isVideoProctored) {
        const { isAllowed, failureReason, mediaDevices  } = await getMediaStream(false)
        if (failureReason?.toLowerCase()?.includes(MEDIA_DEVICE_ERRORS.VIDEO_SOURCE)) {
          setShowPermissionModal(CAM_OCCUPIED)
        } else if(failureReason?.toLowerCase()?.includes(MEDIA_DEVICE_ERRORS.NO_DEVICE) || mediaDevices?.filter(({ kind, label }) => kind === 'videoinput' && !label.toLowerCase().includes('back')).length === 0) {
          setShowPermissionModal(NO_CAM)
        } else {
          setShowPermissionModal(!isAllowed)
        }
        if(!isAllowed) {
          pauseGameTimer()
        }
      }
    })()
  }, [])

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // System has woken up from sleep mode, so force a refresh of the Webcam component
        setWebCamKey(webCamKey + 1);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [webCamKey]);

  useEffect(() => {
    if(showPermissionModal) {
      window.dispatchEvent(new Event(PAUSE_TIMER_EVENT))
    }
  }, [showPermissionModal])

  return (
    <>
      {initialCapture ? (
        <div className="flex flex-col">
          <div className="flex justify-center w-full">
            {imgSrc ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={imgSrc}
                  alt="user"
                  style={{
                    width: '300px',
                    height: '300px',
                    borderRadius: '50%',
                  }}
                />
                {imageAnalysisData !== null && (
                  <button
                    className="flex items-center font-medium br-20 mt-10"
                    style={{
                      color: 'white',
                      background: 'none',
                      borderStyle: 'none',
                      cursor: 'pointer',
                    }}
                    onClick={onRetake}
                  >
                    <span className='flex items-center mb-4 mr-4'>
                      <Camera />
                    </span>
                    {' '}
                    Take Again
                  </button>
                )}

                {imageAnalysisData === null ? (
                  <>
                    <LoadingOutlined className="size-24 mt-20" spin />
                    <p className="mt-10 size-14 font-light opacity-70 color-primary">
                      This might take some time.
                    </p>
                  </>
                ) : imageAnalysisData?.isImageCritical ? (
                  <>
                    <p className="color-danger align-center size-14 font-medium mt-10 mb-10">
                      {`${imageAnalysisData.criticalReason} ${imageAnalysisData.criticalReason?.startsWith("Make") ? "" : `, please try again ${!isChrome ? `in Google Chrome` : ""}`} `}
                    </p>
                  </>
                ) : (
                  <div className="mt-40 flex flex-col items-center">
                    <div className="flex items-center">
                      <Checkbox
                        checked={consent}
                        onChange={(e) => setConsent(e.target.checked)}
                        className="mr-14 relative bottom-8"
                      />
                      <p className="size-18 color-secondary">
                        By checking this box, I agree to the
                        <a
                          className="size-18 font-bold color-primary"
                          href={privacyPolicyLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {' '}
                          Privacy Policy
                        </a>
                        .
                      </p>
                    </div>
                    <Button
                      type="primary"
                      className="font-medium br-20"
                      style={{
                        width: '150px',
                      }}
                      onClick={() => confirmPhoto({ cameraSupport: true })}
                      loading={loading}
                      disabled={imageAnalysisData?.isImageCritical || !consent}
                    >
                      Continue
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                {devices === null ? (
                  <LoadingOutlined className="size-24 mt-20" spin />
                ) : devices.length === 0 ? (
                  <>
                    <Result
                      status="warning"
                      title={
                        <p className="color-primary size-18">
                          {
                            `${isVideoProctored ? "There seems to be some issue with your device’s camera. Please switch to some other device to take the assessment." : "No camera device detected, don't worry it wont impact your score - we will inform the concerned team about this technical glitch."}`
                          }
                        </p>
                      }
                      extra={!isVideoProctored ?
                        <>
                          <div className="flex items-center justify-center">
                            <Checkbox
                              checked={consent}
                              onChange={(e) => setConsent(e.target.checked)}
                              className="mr-14 relative bottom-8"
                            />
                            <p className="size-18 color-secondary">
                              By checking this box, I agree to the
                              <a
                                className="size-18 font-bold color-primary"
                                href={privacyPolicyLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {' '}
                                Privacy Policy
                              </a>
                              .
                            </p>
                          </div>
                          <Button
                            type="primary"
                            className="font-medium br-20"
                            style={{
                              width: '150px',
                            }}
                            onClick={() =>
                              confirmPhoto({ cameraSupport: false })
                            }
                            disabled={!consent}
                          >
                            Continue
                          </Button>
                        </> : <></>
                      }
                    />
                  </>
                ) : (
                  <>
                    <p className="size-14 font-light color-primary">
                      Place your face in the frame and click picture
                    </p>
                    <Webcam
                      key={webCamKey}
                      muted={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      mirrored
                      onError={(error) => {
                        console.error('Error in webcam: ', error);
                      }}
                      style={{
                        borderRadius: '20px',
                        width: isMobile ? '100%' : '350px',
                        height: isMobile ? '100%' : '350px', //isMobile ? '250px' : '350px',
                      }}
                      videoConstraints={{
                        width: 512,
                        height: 512,
                        facingMode: 'user',
                        deviceId: deviceId,
                      }}
                    />
                    <p className="size-14 align-center font-medium opacity-70 color-primary mt-10">
                      If video is not showing above, try changing the source
                      using the below dropdown
                    </p>
                    <Select
                      className="size-14 max-width-300"
                      suffixIcon={<Dropdown />}
                      value={deviceId}
                      options={devices.map(({ label, deviceId }) => ({
                        label,
                        value: deviceId,
                      }))}
                      onChange={saveWebCamDevice}
                    />
                    <Button
                      type="primary"
                      className="font-medium br-20 mt-40"
                      style={{
                        width: '150px',
                      }}
                      onClick={capture}
                    >
                      Click Picture
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Webcam
          style={{
            position: 'absolute',
            right: '100%',
          }}
          key={webCamKey}
          muted={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          mirrored
          videoConstraints={{
            deviceId: deviceId,
          }}
        />
      )}
      {/* If camera is detected and permission denied, then show the modal.
      Else if camera is not detected, dont show the modal and let the user complete the gameplay
       */}
      {
        devices?.length >= 0 
        ? <PermissionsModal 
            visible={initialCapture && showPermissionModal === NO_CAM ? false : showPermissionModal} 
            type={showPermissionModal === CAMERA_FEED
              || showPermissionModal === CAM_OCCUPIED
              || showPermissionModal === NO_CAM
              ? showPermissionModal
              : "camera"} 
          />
        : <></>
      }
    </>
  );
};

export default WebcamCapture;

import { useEffect, useRef, useState } from "react";
import { TAB_SWITCH_EVENT } from "constants/helperModalConstants";
import { storage } from "services/config/storage";
import { DISQUALIFY_REASONS } from "constants/globalConstants";
import { saveTabSwitchCount } from "utils/helper";

export const useTabSwitch = ({
    tabSwitchLimit,
    setUserDisqualificationReason,
    setShowTabWarning,
    linkId,
}) => {
    const [isTabWarningFinal, setIsTabWarningFinal] = useState(false)
    const tabClosed = useRef(false)

    /**
     * Send 1 in payload as total count will be incremented by 1 on BE.
     */
    const handleTabWarningPopup = async () => {
        setShowTabWarning(true)
        await saveTabSwitchCount(linkId, 1)
    }

    /**
     * Set this to true if tab is closed. This value is checked in handleVisibilityChange
     * to exclude count update on closing the tab.
     */
    const handleBeforeUnload = () => {
        tabClosed.current = true
    }

    /**
     * TAB_SWITCH_EVENT triggered from handleVisibilityChange.
     * beforeunload to exclude tab closing scenario.
     */
    useEffect(() => {
        window.addEventListener(TAB_SWITCH_EVENT, handleTabWarningPopup)
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener(TAB_SWITCH_EVENT, handleTabWarningPopup)
            window.removeEventListener('beforeunload', handleBeforeUnload)
        };
    }, [])

    useEffect(() => {
        document.addEventListener("visibilitychange", handleVisibilityChange)
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange)
    }, [tabSwitchLimit])

    /**
     * setTimeout is used to exclude the page refresh scenario.
     * First if condition - If tab is switched, not closed and no full screen is enabled (to exclude full screen scenario).
     * Second if condition - Is full screen config is true, then disqualify then and there.
     * Trigger custom event due to which control flow goes to handleTabWarningPopup.
     * If count is less than limit then simply update the count and show a warning.
     * If count is equal to tab switch limit then update the count and show final warning.
     * If count is more tan tab switch limit, then disqualify. 
     *  */
    function handleVisibilityChange() {
        setTimeout(() => {
            if (!tabClosed.current && document.visibilityState === 'hidden' && !document?.webkitFullscreenElement && !document?.webkitCurrentFullScreenElement && !document?.fullscreenElement && !document?.mozFullScreenElement) {
                const allCount = storage.get.allTabSwitchCount()
                    const updatedAllCount = allCount + 1
                    window.dispatchEvent(new Event(TAB_SWITCH_EVENT))
                    if (updatedAllCount <= tabSwitchLimit) {
                        storage.set.allTabSwitchCount(updatedAllCount)
                        if (updatedAllCount == tabSwitchLimit) {
                            setIsTabWarningFinal(true)
                        }
                    }
                    else if (updatedAllCount >= (tabSwitchLimit + 1)) {
                        setUserDisqualificationReason(DISQUALIFY_REASONS.TAB_SWTCH)
                    }
            }
        }, 0)
    }

    return { isTabWarningFinal }
}

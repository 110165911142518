const validateEmail = (_, value) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
  if (!value || emailRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Please enter a valid email'));
};

const validatePhone = (_, value, countryCode) => {
  const phoneRegex = countryCode === "91"
  ? /^(?:[()\-\+\s]*\d){10}[()\-\+\s]*$/ 
  : /^(?:[()\-\+\s]*\d){4,14}[()\-\+\s]*$/;
  if (!value || phoneRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Please enter a valid phone number'));
};

/**
 * 
 * Handling for phone and overall experience fields
 * Overall experience field -> atmost one decimal allowed, handled in else condition
 * 
 */
const handleKeyPress = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  const phoneFieldEvent = event.target.name === "phone";
  if (phoneFieldEvent) {
    if (
      (charCode < 48 || charCode > 57) && // numbers 0-9
      ![40,41,43,45,91,93].includes(charCode) 
    ) {
      event.preventDefault();
    }
  } else {
    if (
      (charCode !== 46 && (charCode < 48 || charCode > 57)) ||
      (charCode === 46 && event.target.value.includes('.'))
    ) {
      event.preventDefault();
    }
  }
};

export const validators = {
  validateEmail,
  validatePhone,
  handleKeyPress
};
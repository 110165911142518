import { Api } from "services/config/request"

const mediaApi = {
    uploadMedia(payload) {
        let url = `/api/media/v1`
        return Api.post(url, payload, {
            isFormData: true
        })
    }
}

export default mediaApi
import AuthApi from '../../services/apis/auth';
import GamePlayApi from '../../services/apis/gameplay';
// import { handleError } from '../../../utils/logger/errorHandler';
import * as authActionCreators from './actionCreators';
import { storage } from '../../services/config/storage';

export const sendOtpEmail = (payload) => async (dispatch) => {
  try {
    dispatch(authActionCreators.dataFetching(null));
    const response = await AuthApi.sendOtp(payload);
    if (response.success) {
      dispatch(authActionCreators.dataFetched(response));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
    console.log(err);
  }
};
export const verifyOtp = (payload) => async (dispatch) => {
  try {
    dispatch(authActionCreators.dataFetching(null));
    const response = await AuthApi.verifyOtp(payload);
    if (response.success) {
      storage.set.authToken(response.token);
      storage.set.fcData(response.user);
      dispatch(authActionCreators.dataFetched(response));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
    console.log(err);
  }
};
export const verifyThirdPartyLogin = (payload) => async (dispatch) => {
  try {
    dispatch(authActionCreators.dataFetching(null));
    const response = await AuthApi.verifyThirdPartyLogin(payload);
    if (response.success) {
      storage.set.authToken(response.token);
      storage.set.fcData(response.user);
      dispatch(authActionCreators.dataFetched(response));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
    console.log(err);
  }
};

export const syncGameData = ({ linkId }) => async (dispatch) => {
  try {
    dispatch(authActionCreators.dataFetching(null));
    const response = await GamePlayApi.syncLinkData(linkId);
    
    console.log(`syncGameData:action:response`, response)
    
    if (response.success) {
      dispatch(authActionCreators.syncGameData({ data: response.games }));
    }
    return response;
  } catch (err) {
    dispatch(authActionCreators.errorFetching(err));
  }
}
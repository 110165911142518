import { useIsMobileDevice } from "hooks"
import './style.scss'
const ThankYou = ({
    message,
  }) => {
    const isMobile = useIsMobileDevice()
    return (
        <div className={`message-container thankyou-opacity flex flex-col items-center justify-center`}>
            <span className='heading-emoji'>
                🎉
            </span>
            <span className="heading mb-18">
                Thank You
            </span>
            <span className={`sub-heading mb-5 font-medium ${isMobile ? "text-center" : "align-center"}`}>
                {message ?? 'We highly value your feedback, we will continue giving you a great experience ❤️'}
            </span>
        </div>
    )
}

export default ThankYou
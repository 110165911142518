import React from 'react';
import { notification } from 'antd';
import ToastTemplate from './ToastTemplate';
import './style.scss';
import { Cross } from 'assets/icons';

const Toast = (
  type = 'success',
  description = '',
  message = '',
  placement = 'topRight'
) => {
  notification.open({
    className: 'toster',
    placement,
    duration:
      type === 'success' || type === 'lowinternet' || type === 'nointernet'
        ? 5
        : 6,
    closeIcon:
      type === 'lowinternet' || type === 'nointernet' ? null : (
        <Cross />
      ),
    description: (
      <ToastTemplate type={type} description={description} message={message} />
    ),
  });
};

export default Toast;

import { Button, Form, Input } from 'antd';
import Toast from 'components/dumb/Toast';
import VerifyOtp from 'components/smart/VerifyOtp';
import UnberryModal from 'components/dumb/UnberryModal';
import UserForm from 'components/smart/UserForm';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import * as AuthSuppliers from 'store/auth/actions';
import { AUTH_CONSTANTS } from '../../constants/authConstants';
import GamePlayApis from '../../services/apis/gameplay';
import AuthApi from '../../services/apis/auth';
import * as LoadingScreenUtils from './screenUtils';
import ProctoringApi from 'services/apis/proctoring';
import { storage } from '../../services/config/storage';
import * as Sentry from '@sentry/react';

// styles
import './style.scss';
import { initializeFreshChat } from 'services/freshchat';
import { useIsMobileDevice } from 'hooks';

import HeroPageTemplate from 'components/dumb/HeroPageTemplate';
import PhoneInput from 'components/smart/PhoneInput';
import { COUNTRY_DATA, DEFAULT_COUNTRY_DATA, GODREJ_SECTION_IDS, LOGIN_TYPE, ORG_SET_EVENT } from 'constants/globalConstants';
import { validators } from 'views/SignUp/validate';
import { useGameplayConfig } from 'store';
import { Cross, ErrorWarning } from 'assets/icons';

const LoadingScreen = (props) => {
  // ===================
  // props from parent
  // ===================
  // props from redux
  // ===================
  const error = useSelector(({ auth }) => auth.error);
  const dispatch = useDispatch();

  // ===================
  // custom hooks
  // ===================
  const isMobile = useIsMobileDevice();
  const [userDetailForm] = Form.useForm();
  const [loginForm] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  let { id: linkId } = useParams();
  const {
    positionId,
    isVideoProctored,
    customizableForm,
    setIsVideoProctored,
    setIsProctored,
    fetchLinkConfig,
  } = useGameplayConfig()

  // ===================
  // constant variables  
  // ===================
  const loginType = storage.get.loginType();
  const hasPhoneLogin = loginType === LOGIN_TYPE.PHONE;
  const hasThirdPartyLogin = loginType === LOGIN_TYPE.THIRD_PARTY;

  const hasGodrejPosition = GODREJ_SECTION_IDS.includes(positionId);

  // Re-using component for login through positionId/linkId
  const hasPositionLogin = location.pathname.includes("/login");
  const companyLogo = storage.get.companiesLogos();
  const orgInfo = storage.get.orgInfo()

  const USER_LOGIN_INITIAL_VALUES = {
    candidateEmail: '',
    ...((hasPhoneLogin) && { phone: '' }),
    ...(hasThirdPartyLogin && { candidateKey: '' })
  };

  const USER_PROFILE_INITIAL_VALUES = {
    designation: '',
    dob: '',
    name: '',
  };
  // ===================
  // useStates
  // ===================
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [otpLoginSuccess, setOtpLoginSuccess] = useState(true);
  const [userData, setUserData] = useState(null);
  const [candidateEmail, setCandidateEmail] = useState('');
  const [apiInProgress, setApiInProgress] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [maskUrlComp, setMaskUrlComp] = useState(true);
  const [candidateKey, setCandidateKey] = useState('');
  const [candidatePhone, setCandidatePhone] = useState(null);
  const [country, setCountry] = useState(DEFAULT_COUNTRY_DATA);

  // ===================
  // useRefs
  // ===================

  // ===================
  // computed variables
  // ===================

  // ===================
  // functions
  // ===================

  /**
   * This function updates the state value of proctoring and returns a boolean value to the 
   * calling function as well. This is done because states do not update their values in same render
   * cycle.
   * @return {isVideoSessionRequired} - true if gameplayconfig or linkconfig returns saveProctoredVideo as true
   */
  const updateGameplayConfig = async () => {
    let linkConfigResponse
    try {
      linkConfigResponse = await fetchLinkConfig(linkId)
      if (linkConfigResponse.success) {
        if (linkConfigResponse.linkPositionConfig) {
          setIsVideoProctored(!!linkConfigResponse?.linkPositionConfig?.saveProctoredVideo)
          setIsProctored(!!linkConfigResponse?.linkPositionConfig?.proctoring)
        }
      }
    } catch (err) {
      console.err(err)
    } finally {
      return { isVideoSessionRequired: isVideoProctored || !!linkConfigResponse?.linkPositionConfig?.saveProctoredVideo }
    }
  }

  const createVideoSession = async () => {
    storage.set.videoOrderCount(0)
    const { sessionId = "" } = await ProctoringApi.getVideoSessionId(linkId)
    storage.set.videoSessionId(sessionId)
  }

  /**
   *
   * @param {string} candidateEmail
   */
  const onCandidateKeySubmit = async ({ candidateKey, candidateEmail, phone, countryCode, posId = '' }) => {
    setCandidateKey(candidateKey);
    setCandidatePhone(phone);
    const getPositionId = posId || positionId;
    const data = {
      candidateKey,
      email: candidateEmail,
      positionId: getPositionId,
    };
    try {
      const result = await dispatch(AuthSuppliers.verifyThirdPartyLogin(data));
      if (result) {
        const { isVideoSessionRequired } = await updateGameplayConfig()
        if (isVideoSessionRequired) {
          createVideoSession()
        }
        const { user } = result;
        setSentryContext(user);
        initializeFreshChat(user, isMobile);
        // show user info form if name is not present for user
        redirectToGames(false, result?.linkId);
      } else {
        console.log(props);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   *
   * @param {string} candidateEmail
   */
  const onEmailSubmit = async ({ candidateEmail, phone, countryCode, posId = '' }) => {
    setCandidateEmail(candidateEmail);
    setCandidatePhone(phone);
    const getPositionId = posId || positionId;
    const data = {
      route: AUTH_CONSTANTS.LOGIN_ROUTE_ORIGIN,
      positionId: getPositionId,
      ...(!hasPhoneLogin && { email: candidateEmail }),
      ...(hasPhoneLogin && { phone }),
      ...(hasPhoneLogin && { countryCode: countryCode || country?.phone }),
    };
    try {
      const result = await dispatch(AuthSuppliers.sendOtpEmail(data));
      if (result) {
        setShowOTPModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // userLinkId -> Used in case of postionLogin
  const redirectToGames = async (replace = false, userLinkId) => {
    try {
      setShowUserDetailsModal(false);
      const candidateLinkId = hasPositionLogin ? userLinkId : linkId;
      if (candidateLinkId) {
        const response = await dispatch(AuthSuppliers.syncGameData({ linkId: candidateLinkId }));
        console.log(`[Sync game data] success`);
        if (response) {
          storage.set.orgId(response?.games?.position?.organizationId)
          // Prevents multiple users from accessing same device since isUserDisqualified -> re-routes to home.
          // Clear/Destroy LS key here since purpose completed to bring user to /home route. 
          const isUserDisqualified = !!storage.get.userDisqualified();
          if (isUserDisqualified) storage.destroy.userDisqualified();
          replace
            ? history.replace(`/${customizableForm ? "form" : "rules"}/${candidateLinkId}`)
            : history.push(`/${customizableForm ? "form" : "rules"}/${candidateLinkId}`);
        } else {
          setShowOTPModal(false);
        }
      } else {
        history.push(`/rules`);
      }
    } catch (error) {
      Toast('error', error.message);
    }
  };

  const setSentryContext = (user) => {
    try {
      Sentry.setUser({ email: user.email, username: user.name, id: user.id });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /**
   * submit OTP
   * @param {string} otp
   */
  const onOTPSubmit = async (otp) => {
    const data = {
      otp: otp,
      route: AUTH_CONSTANTS.LOGIN_ROUTE_ORIGIN,
      ...(!hasPhoneLogin && { email: candidateEmail }),
      ...(hasPhoneLogin && { phone: candidatePhone }),
      ...(hasPhoneLogin && { countryCode: country.phone }),
      positionId
    };

    try {
      const result = await dispatch(AuthSuppliers.verifyOtp(data));
      if (result) {
        const { user } = result;
        setShowOTPModal(false);
        setSentryContext(user);
        const showGameplayOnly = storage.get.showGameplayOnly();
        if (!showGameplayOnly) {
          initializeFreshChat(user, isMobile);
        }
        storage.set.allTabSwitchCount(result?.tabSwitched ?? 0)
        const { isVideoSessionRequired } = await updateGameplayConfig()
        if (isVideoSessionRequired) {
          createVideoSession()
        }
        // show user info form if name is not present for user
        const { name, designation } = user;
        if (!name) {
          userDetailForm.setFieldsValue({ name, designation });
          setShowUserDetailsModal(true);
          setUserData(user);
        } else {
          // Redirect user to linkId received in result.
          if (hasPositionLogin) {
            redirectToGames(false, result?.linkId);
          } else {
            redirectToGames();
          }
        }
      } else {
        setOtpLoginSuccess(false);
        console.log(props);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * updates user's profile with folowing values
   * @param {Object} values { name: '', designation: '', dob: '' }
   */
  const onUserDetailsSubmit = async (values) => {
    const payload = {
      ...values,
      // dob: moment(values.dob).format('YYYY-MM-DD'),
    };
    try {
      setApiInProgress(true);
      const response = await GamePlayApis.updateUserProfile(
        userData.id,
        payload
      );
      if (response) {
        setShowUserDetailsModal(false);
        setApiInProgress(false);
        redirectToGames();
      }
    } catch (err) {
      setApiInProgress(false);
      console.log('error', err);
    }
  };

  /**
   show error screen if login bypass fails
  */
  const MaskedUrlErrorComponent = () => (
    <>
      <p className="color-secondary sub-content opacity font-medium size-20 mt-30 mb-40">
        Some error occured. Open link from email again or follow below link for
        further instructions.
      </p>
      <p className="color-secondary sub-content opacity font-medium flex justify-center items-center size-20 mt-30 mb-40">
        <a target="_blank" href={storage.get.url() ? storage.get.url() : '#'}>
          Click Here
        </a>
      </p>
    </>
  );
  const bypassOtpAuth = async () => {
    try {
      const response = await AuthApi.getUserInfo();
      if (response.success) {
        const showGameplayOnly = storage.get.showGameplayOnly();
        const { user } = response;
        setSentryContext(user);
        if (!showGameplayOnly) {
          initializeFreshChat(user, isMobile);
        }
        storage.set.fcData(user);
        redirectToGames(true);
      }
    } catch (err) {
      setShowLoader(false);
      console.error('error', err);
    }
  };

  // ===================
  // useCallback
  // ===================

  // ===================
  // useEffects
  // ===================

  // To bypass login authentication if url contains auth code and callback url
  // config stat not needed as useGameplayConfig is not dependent on url change anymore. hence, this page is
  // not mounting twice and the otp call hits only once.
  useEffect(() => {
    if (location.state) {
      const {
        email: candidateEmail,
        country: candidateCountry,
        phone,
        candidateKey,
        posId
      } = location.state;
      if (candidateCountry) setCountry(candidateCountry);
      if (candidateKey) setCandidateKey(candidateKey);
      if (hasThirdPartyLogin) {
        loginForm.setFieldsValue({ candidateEmail, candidateKey });
        onCandidateKeySubmit({
          candidateKey,
          candidateEmail,
          posId,
        });
      } else {
        loginForm.setFieldsValue({ candidateEmail, phone });
        onEmailSubmit({
          candidateEmail,
          phone,
          posId,
          ...(candidateCountry && { countryCode: candidateCountry.phone })
        });
      }
    }
  }, []);

  /**Dynamic country code from org config as per Neha. */
  useEffect(() => {
    const countryCode = orgInfo?.organizationConfig?.countryCode ?? "91"
    const country = COUNTRY_DATA?.find(cuntry => cuntry?.phone == countryCode)
    setCountry(country)
    const initiateFC = () => {
      if (!window.fcWidget?.isInitialized()) {
        const data = storage.get.fcData() ?? null;
        initializeFreshChat(data, isMobile);
      }
    }
    window.addEventListener(ORG_SET_EVENT, () => {
      console.log("Initialize FC");
      const showGameplayOnly = storage.get.showGameplayOnly();
      if (!showGameplayOnly) {
        initiateFC();
      }
    });
    return () => window.removeEventListener(ORG_SET_EVENT, initiateFC);
  }, []);

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    const auth = queryParams.get('auth');
    const url = queryParams.get('url');
    const showGameplayOnly = JSON.parse(
      queryParams.get('showGameplayOnly') ?? 'false'
    );
    storage.set.linkId(linkId);

    if (auth && (url || showGameplayOnly)) {
      const feedbackAllowed = JSON.parse(queryParams.get('feedbackAllowed'));

      // set Params to local storage
      storage.set.authToken(auth);
      storage.set.url(url);
      storage.set.showGameplayOnly(showGameplayOnly);
      storage.set.feedbackAllowed(feedbackAllowed);

      // Remove params from url
      // queryParams.delete('url');
      // queryParams.delete('auth');
      // history.location.search = queryParams?.toString();
      // history.replace(history.location);

      // fetch User Data to bypass OTP
      bypassOtpAuth();
    } else if (auth && !url) {
      // showing error in case url is missing
      setShowLoader(false);
    } else {
      // if auth & url missing show normal flow
      setShowLoader(false);
      setMaskUrlComp(false);
    }
  }, []);

  useEffect(() => {
    if (!showOTPModal) setOtpLoginSuccess(true);
  }, [showOTPModal]);

  return maskUrlComp ? (
    <section className="overflowY-hidden overflowX-hidden h-full-page bg-color-primary flex flex-col justify-center items-center">
      {showLoader ? (
        <span className="loader"></span>
      ) : (
        <MaskedUrlErrorComponent />
      )}
    </section>
  ) : (
    <section className="home-container overflowX-hidden bg-color-primary flex flex-col justify-between items-center pt-25 pb-25">
      <HeroPageTemplate
        heading={hasGodrejPosition ? 
          // "GPL ALCHEMY || OPERATIONS KNOWLEDGE ASSESSMENT 2024" :
        "GPL Alchemy: Post Workshop Assessment : Sequence Essentials" : 
          loginType === LOGIN_TYPE.THIRD_PARTY ? "Delhivery Skills Development Program: Online Entrance Exam" : isMobile ? ['Welcome', <br />, 'aboard! 👋🏻'] : "Welcome aboard! 👋🏻"}
        content="Hello! We’re so excited to have you participate in this experience. Take a deep breath and jump right in by entering your details below."
        companyLogo={companyLogo}
        hideBgBars={isMobile}
        isMobile={isMobile}
        headingSize={60}
      >
        <UserForm
          name="emailSubmitForm"
          submitForm={hasThirdPartyLogin ? onCandidateKeySubmit : onEmailSubmit}
          formInitialValues={USER_LOGIN_INITIAL_VALUES}
          form={loginForm}
        >
          <div className="form">
            {
              error ?
                <p className="error color-primary size-14 mb-10 p-10 flex items-center">
                  <span className='flex items-center mr-4'>
                    <ErrorWarning />
                  </span>
                  {
                    // error === "This assessment is not active yet, please try after designated time or contact your HR for more details." ? 
                    // "This assessment is not active as the designated time is over." :
                    error === "User is not whitelisted for this position." ?
                      "Details provided by you do not match in our system. Please check the details and try again." :
                      error
                  }
                </p> : <></>
            }
            {
              !(hasPhoneLogin) &&
              <Form.Item
                name="candidateEmail"
                rules={[
                  {
                    required: !hasPhoneLogin ? true : false,
                    message: 'Please enter an email',
                  },
                  {
                    type: 'email',
                    validateTrigger: 'onSubmit',
                    message: 'Please enter a valid email',
                  },
                ]}
              >
                <Input
                  autoComplete="off"
                  autoCapitalize="off"
                  placeholder="Enter your email ID"
                  className="email-input"
                  onPaste={({ target: { value } }) => { loginForm.setFieldsValue({ candidateEmail: value.trim() }) }}
                  onChange={({ target: { value } }) => { loginForm.setFieldsValue({ candidateEmail: value.trim() }) }}
                />
              </Form.Item>
            }
            {
              hasPhoneLogin &&
              <PhoneInput
                className="constraint-width"
                setValue={setCandidatePhone}
                country={country}
                setCountry={setCountry}
              />
            }
            {
              hasThirdPartyLogin &&
              <>
                <Form.Item
                  name="candidateKey"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your candidate ID',
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    autoCapitalize="off"
                    placeholder="Enter your candidate ID"
                    className="email-input"
                    onPaste={({ target: { value } }) => { loginForm.setFieldsValue({ candidateKey: value.trim() }) }}
                    onChange={({ target: { value } }) => { loginForm.setFieldsValue({ candidateKey: value.trim() }) }}
                  />
                </Form.Item>
              </>
            }
            {
              isMobile
                ? <Form.Item>
                  <div className="mobile-continue-btn">
                    <Button
                      className="font-medium br-20 flex justify-center"
                      type="primary"
                      htmlType="submit"
                    >
                      Continue
                    </Button>
                  </div>
                </Form.Item>
                : <Form.Item>
                  <Button className="submit-btn" type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
            }
          </div>
        </UserForm>
      </HeroPageTemplate>
      {isMobile ? (
        <UnberryModal
          bottomAligned={true}
          visible={showOTPModal}
          footer={false}
          width={'430px'}
          onCancel={() => setShowOTPModal(false)}
          bodyStyle={{
            height: '410px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '50px 60px',
            mixBlendMode: 'normal',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          }}
          maskStyle={{ backgroundColor: 'rgba(0,0,0,0.9)' }}
          wrapClassName="otp-model"
          maskClosable={false}
        >
          <VerifyOtp
            onResendOtp={onEmailSubmit}
            countryCode={country.phone}
            candidatePhone={candidatePhone}
            candidateEmail={candidateEmail}
            hasPhoneLogin={hasPhoneLogin}
            otpLoginSuccess={otpLoginSuccess}
            onSubmit={onOTPSubmit}
            loginError={error}
          />
        </UnberryModal>
      ) : (
        <UnberryModal
          visible={showOTPModal}
          footer={false}
          width={'430px'}
          closeIcon={<Cross />}
          onCancel={() => setShowOTPModal(false)}
          bodyStyle={{
            height: 'fit-content',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '50px 60px',
            mixBlendMode: 'normal',
            borderRadius: '20px',
          }}
          maskStyle={{ backgroundColor: 'rgba(0,0,0,0.95)' }}
          wrapClassName="otp-model"
          maskClosable={false}
        >
          <VerifyOtp
            onResendOtp={onEmailSubmit}
            countryCode={country.phone}
            candidatePhone={candidatePhone}
            candidateEmail={candidateEmail}
            hasPhoneLogin={hasPhoneLogin}
            otpLoginSuccess={otpLoginSuccess}
            onSubmit={onOTPSubmit}
            loginError={error}
          />
        </UnberryModal>
      )}

      {/* Update User profile */}
      <UnberryModal
        visible={showUserDetailsModal}
        footer={false}
        closeIcon={<></>}
        keyboard={false}
        // closeIcon={<img src="/assets/icons/cross.svg" alt="cross" onClick={() => setShowUserDetailsModal(false)} />}
        bodyStyle={LoadingScreenUtils.modalBodyStyles}
        maskStyle={LoadingScreenUtils.modalMaskStyles}
      >
        {/* <p className="color-secondary font-medium size-14 mb-10 opacity-70">Step 2/2</p> */}
        <p
          className="color-primary font-bold size-30 mb-30"
          style={{ letterSpacing: '-1.5808px' }}
        >
          Let's hear about you
        </p>
        {/* <p className="color-primary font-medium opacity-70 mb-50" style={{ letterSpacing: '-0.225px' }}>
          Want to work in a company that loves design? sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
        <div className="width-p-100">
          <UserForm
            name="userDetailsForm"
            className="user-details-form"
            submitForm={onUserDetailsSubmit}
            formInitialValues={USER_PROFILE_INITIAL_VALUES}
            form={userDetailForm}
          >
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Enter your full name',
                },
              ]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>
            <Form.Item
              name="designation"
              rules={[
                {
                  required: true,
                  message: 'Designation is required',
                },
              ]}
              className="mb-25"
            >
              <Input placeholder="Enter Designation" />
            </Form.Item>

            {/* <Form.Item
              name="dob"
              rules={[
                {
                  required: true,
                  message: 'D.O.B is required'
                }
              ]}
            >
              <DatePicker format="DD-MM-YYYY" placeholder="Date of Birth" />
            </Form.Item> */}

            <Form.Item noStyle>
              <Button
                loading={apiInProgress}
                style={{ marginTop: '40px' }}
                htmlType="submit"
                type="primary"
                block
              >
                Save &amp; Continue
              </Button>
            </Form.Item>
          </UserForm>
        </div>
      </UnberryModal>
    </section>
  );
};

export default LoadingScreen;

import { LOGIN_TYPE } from "constants/globalConstants";

const ShowAssessmentSectionInstructions = () => {
  return (
    <ul className="color-primary font-medium pl-0">
      
    <li className="flex flex-col ml-0 mb-14">
    <span className="size-18 font-light color-primary">
      1. Make sure you are in a calm environment with no distractions around you.
    </span>
  </li>
    <li className="flex flex-col ml-0 mb-14">
      <span className="size-18 font-light  color-primary">
        2. Make sure you have a stable internet connection at all times.
      </span>
    </li>
    <li className="flex flex-col ml-0 mb-14">
      <span className="size-18 font-light  color-primary">
        3. Each exercise will have a time limit, so be sure to manage your time well.
      </span>
    </li>
    <li className="flex flex-col ml-0 mb-14">
      <span className="size-18 font-light  color-primary">
        4. Avoid any external engagement with people or other devices.
      </span>
    </li>
    <li className="flex flex-col ml-0 mb-14">
      <span className="size-18 font-light  color-primary">
        5. Please use Google Chrome for the best experience.
      </span>
    </li>
    <li className="flex flex-col ml-0 mb-14">
      <span className="size-18 font-light  color-primary">
        6. This test is proctored. You will have to give access to your system’s microphone and camera.
      </span>
    </li>
  </ul>
  )
};

export const Instructions = ({ loginType, showAssessmentSection, hasGodrejPosition }) => {
  if (showAssessmentSection) 
    return <ShowAssessmentSectionInstructions />

  return (
    <ul className="color-primary font-medium rules-list pl-0">

      {
        loginType === LOGIN_TYPE.THIRD_PARTY
          ? <li className="flex flex-col ml-0 mb-20">
            <span className="size-20 font-medium color-primary">
              1. How long will it take
            </span>
            <span className="size-14 font-light opacity-70 color-primary">
              This test will take around 90 minutes to complete
            </span>
          </li> : <></>
      }
      <li className="flex flex-col ml-0 mb-20">
        <span className="size-20 font-medium color-primary">
          {`${loginType === LOGIN_TYPE.THIRD_PARTY
            ? "2. Some essentials"
            : "1. Be your best"}`}
        </span>
        <span className="size-14 font-light opacity-70 color-primary">
          {`${loginType === LOGIN_TYPE.THIRD_PARTY
            ? "Make sure you have a stable internet connection at all times."
            : "Make sure you are in a calm environment with no distractions around you."}`}
        </span>
      </li>
      <li className="flex flex-col ml-0 mb-20">
        <span className="size-20 font-medium color-primary">
          {`${loginType === LOGIN_TYPE.THIRD_PARTY
            ? "3. Stay Focused"
            : "2. Some Essentials"}`}
        </span>
        <span className="size-14 font-light opacity-70 color-primary">
          {`${loginType === LOGIN_TYPE.THIRD_PARTY
            ? "Avoid any external engagement with people or other devices."
            : "Make sure you have a stable internet connection at all times."}`}
        </span>
      </li>
      {
        loginType !== LOGIN_TYPE.THIRD_PARTY ?
          <li className="flex flex-col ml-0 mb-20">
            <span className="size-20 font-medium color-primary">
              3. Manage your time
            </span>
            <span className="size-14 font-light opacity-70 color-primary">
              {
                hasGodrejPosition ? "The assessment has a time limit of 60 minutes. Be sure to manage your time well" : "Each exercise will have a time limit, so be sure to manage your time well."
              }
              
            </span>
          </li> : <></>
      }
      <li className="flex flex-col ml-0 mb-20">
        <span className="size-20 font-medium color-primary">
          {`${loginType === LOGIN_TYPE.THIRD_PARTY
            ? "4. The Results"
            : "4. Stay focused"}`}
        </span>
        <span className="size-14 font-light opacity-70 color-primary">
          {`${loginType === LOGIN_TYPE.THIRD_PARTY
            ? "The results would be announced via email sent to the candidate’s registered email id. Individual scores will not be provided"
            : "Avoid any external engagement with people or other devices."}`}
        </span>
        {
          loginType === LOGIN_TYPE.THIRD_PARTY
            ? <span className="size-12 font-light opacity-50 font-italic color-primary">
              *The company's decision to shortlist or reject a candidate based on their exam scores is final and shall not be subject to review or appeal
            </span> : <></>
        }
      </li>
    </ul>
  );
};
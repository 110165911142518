import './style.scss';

const NotFound = () => {

    return (
        <section className="not-found-container h-full-page color-primary bg-color-primary flex justify-center pl-50 pr-50">
            <div className="h-full-fix width-p-50 flex flex-col justify-center items-center p-30">
            {/* <p className="color-primary font-medium size-40 weight-900">unberry</p> */}
            <p className="color-primary font-medium opacity-70 sub-content size-18">You seem to have landed on the wrong URL, make sure you open the link from email invite received or contact support.</p>
            </div>
      </section>
    )
}

export default NotFound
import { initialDataState } from './constants';
import * as authActionTypes from './actionTypes';
import { storage } from '../../services/config/storage';
import { log } from '../../utils/logger';

export const schema = { ...initialDataState };

const initialState = schema;

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case authActionTypes.DATA_FETCHING:
      return dataFetching(state, payload);

    case authActionTypes.DATA_FETCHED:
      return dataFetched(state, payload);

    case authActionTypes.SYNC_GAME_DATA:
      return syncGameData(state, payload);

    case authActionTypes.ERROR_FETCHING:
      return errorFetching(state, payload);

    default:
      return state;
  }
}

const reset = (state, payload) => {
  return {
    ...schema,
  };
};

const dataFetching = (state, payload) => {
  return {
    ...state,
    data: null,
    fetching: true,
    error: null,
    loading: true,
  }
};

const dataFetched = (state, payload) => {
  if (!payload) {
    return {
      ...initialDataState,
    };
  }
  return {
    ...state,
    data: payload,
    fetching: false,
    error: null,
    loading: false,
  }
};

const syncGameData = (state, { data }) => {
  console.log(`syncGameData:payload`, data)
  const { sessions, position: {positionLogo, organizationLogo} } = data;

  let gameIdsAlreadyPlayed = [];

  // if user has played atleast one game with the link
  if (sessions.length > 0) {
    // game's ids that are already played
    gameIdsAlreadyPlayed = sessions
    .filter(
      (game) =>
        Object.keys(game.gameData).length !== 0 &&
        game.gameData.levelData?.length > 0
    )
    .map(game => game.id)
  }

  log(`gameIdsAlreadyPlayed`, gameIdsAlreadyPlayed)
  storage.set.listOfPlayedGamesIds(gameIdsAlreadyPlayed);

  if(positionLogo && organizationLogo) {
    storage.set.companiesLogos({positionLogo, organizationLogo})
  }

  return {
    ...state,
    // syncedLevel: currentGameIndex
  }
};

const errorFetching = (state, payload) => {
  if (!payload) {
    return {
      ...initialDataState,
    };
  }

  return {
    ...state,
    data: null,
    fetching: false,
    error: payload?.message,
    loading: false,
  }
};

import { useState } from "react";
import Flag from 'react-flagkit';
import { Form, Input, InputNumber, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {countries} from "countries-list";

import { COUNTRY_DATA } from "constants/globalConstants";
import { validators } from "views/SignUp/validate";
import "./style.scss";

const { Option } = Select;

const FlagAndCountryCodeComponent = ({ country, setCountry, className }) => {

  const { countryAbrev, phone } = country;
  const [countryList, setCountryList] = useState(COUNTRY_DATA);
  return (
    <div className={`flag-country-code-container cursor-pointer ${className}`}>
      <Select
        className="country-code-select"
        style={{ width: 150 }}
        dropdownMatchSelectWidth={false}
        popupClassName="country-code-dropdown"
        dropdownRender={(menu) => (
          <>
            <div className="flag-search flex items-center relative">
              <SearchOutlined className="flag-search-icon"/>
              <input
                className="flag-search-input"
                onChange={({ target: { value }}) => setCountryList(
                  COUNTRY_DATA.filter(country => {
                    // Check for number search witt/out + prefix
                    const checkNumericValue = value.startsWith('+') ? value.substring(1) : value;
                    return (
                      country.name.toLowerCase().includes(value.toLowerCase()) || 
                      country?.phone?.includes(checkNumericValue)
                      )
                    })
                )} 
                placeholder="Search here..."
              />
            </div>
            {menu}
          </>
        )}
        onChange={(value, _option) => 
          setCountry({ ...countries[value], countryAbrev: value })
        }
        value={countryAbrev}
      >
      {
        countryList.map((country) => {
          const { countryAbrev: countryKey, name, phone: countryExt } = country;
          return (
            <Option
             key={countryKey}
             value={countryKey}
             label={name}
            >
              <div class="flag-container">
                <Flag country={countryKey} />
              </div>
              <span>{`(+${countryExt})`}</span>
              <span>{name}</span>
            </Option>
          )
        })
      }
    </Select>
    <div className="flag-phone-ext flex items-center">
      <div class="flag-container">
        <Flag country={countryAbrev} />
      </div>
      <span className="ml-10 color-primary">{`+${phone}`}</span>
      <div className="dropdown-arrow">
        <div className="arrow"></div>
      </div>
      {/* <div className="country-code-separator"></div> */}
    </div>
  </div>
  )
};
 
const PhoneInput = ({ 
  setValue, 
  placeholder, 
  country, 
  setCountry, 
  hasPhoneLogin=true, 
  className="",
  overlayClassName="" 
}) => {

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const { validatePhone, handleKeyPress } = validators;

  return (
    <div className={`phone-input relative ${className ? className : "mb-10"}`}>
      <FlagAndCountryCodeComponent 
        country={country} 
        setCountry={setCountry}
        className={overlayClassName}
      />
      <Form.Item
        name="phone"
        rules={[
          {
            required: hasPhoneLogin,
            message: 'Please input your phone number',
          },
          {
            validator: (_, val) => validatePhone(_, val, country.phone),
          },
        ]}
      >
        <Input
          name="phone"
          onKeyPress={handleKeyPress}
          onChange={handleChange}
          placeholder={placeholder ?? 'Enter your phone number'}
          autoComplete="off"
        />
      </Form.Item>
    </div>
  )
};

export default PhoneInput;
import { storage } from 'services/config/storage'
import { getImageData } from 'utils/helper'
import ProctoringApi from "services/apis/proctoring"

export const useImgComparison = ({ linkId }) => {

    const proctorGameplayCapture = (imageSrc) => {
        const worker = new Worker(new URL("../utils/imageProctoringWorker.js", import.meta.url))

        worker.onmessage = async (event) => {
            const obj = event.data
            await ProctoringApi.sendUserSnapShot({ linkId, imgSrc: imageSrc, isImageCritical: obj.isImageCritical, criticalReason: obj.criticalReason })
            worker.terminate()
        }

        const referenceImgElement = new Image()
        referenceImgElement.src = storage.get.userImg()
        referenceImgElement.onload = async () => {
            const referenceImgData = getImageData(referenceImgElement)

            const currentImgElement = new Image()
            currentImgElement.src = imageSrc
            currentImgElement.onload = async () => {
                const currentImgData = getImageData(currentImgElement)
                try {
                    worker.postMessage({ currentImgData, referenceImgData })
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }

    return { proctorGameplayCapture }
}
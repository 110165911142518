export const Note = ({visible}) => {
    return visible ? (
        <div className='note-div p-16 br-10 mt-20 mb-20'>
                  <h3 className=''>IMPORTANT NOTE</h3>
                  <ol>
                    <li className='size-16 font-light opacity-70 color-primary'>
                      This session is video proctored
                    </li>
                    <li className='size-16 font-light opacity-70 color-primary'>
                      Please do not switch tabs, or else you will be disqualified
                    </li>
                    <li className='size-16 font-light opacity-70 color-primary'>
                      Don’t speak with anyone during the entire test duration, or else you will be disqualified.
                    </li>
                    <li className='size-16 font-light opacity-70 color-primary'>
                      Don’t use any other device during the test, or else you will be disqualified.
                    </li>
                  </ol>
                </div>
    ) : <></>
}
/* This is a reusable form component. Use this to create forms across the application and pass in the 
prop names as they are defined here. */

import React from 'react';
import { Form } from 'antd';

const UserForm = ({
  children,
  formName,
  submitForm,
  submitFail,
  formInitalValues,
  form,
  ...props
}) => {
  return (
    <Form
      name={formName}
      onFinish={submitForm}
      onFinishFailed={submitFail}
      initialValues={formInitalValues}
      requiredMark="optional"
      form={form}
      {...props}
    >
      {children}
    </Form>
  );
};

export default UserForm;

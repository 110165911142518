/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import OtpInput from 'react-otp-input';
import * as LoadingScreenUtils from 'views/LoadingScreen/screenUtils';


import "./style.scss";
import { useIsMobileDevice } from 'hooks';
export default function index(props) {

  const {
    candidateEmail,
    candidatePhone,
    countryCode,
    hasPhoneLogin,
    onSubmit,
    onResendOtp,
    otpLoginSuccess,
    loginError
  } = props;

  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [otpResend, setOtpResend] = useState(false);
  const [counter, setCounter] = useState(30);
  const isMobile = useIsMobileDevice()

  const displayCandidateDetail = hasPhoneLogin ?
    `+(${countryCode})-${candidatePhone}` :
    candidateEmail;

  useEffect(() => {
    if (counter <= 0) {
      clearTimeout(otpTimer);
    }
  }, [counter]);

  const otpTimer = setTimeout(() => {
    setCounter(counter - 1);
  }, 1000);

  const resendOtp = () => {
    setOtp('');
    setCounter(30);
    if (hasPhoneLogin) onResendOtp({ phone: candidatePhone });
    else onResendOtp({ candidateEmail });
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      onSubmit(otp)
    }
  }

  return <div className="verify-otp">
    <div className='flex flex-col align-center'>
      <p className="model-heading color-primary font-medium size-40 mb-4" style={{ letterSpacing: '-1.5808px' }}>Enter the OTP</p>
      <div className={`flex flex-col mb-50`}>
        <p className={`color-primary font-medium verify ${isMobile ? 'mb-5' : 'mb-0'} mr-3`}>
          We've sent you a code on
        </p>
        <p style={{ lineHeight: '20px' }} className="color-primary font-medium">
          {displayCandidateDetail}
        </p>
      </div>
    </div>
    <div className='flex justify-center' onKeyPress={handleKeyPress}>
      <OtpInput
        value={otp}
        onChange={(val) => setOtp(val)}
        numInputs={4}
        inputStyle={LoadingScreenUtils.otpStyles}
        errorStyle={LoadingScreenUtils.errorStyles}
        hasErrored={otpError}
        isInputNum={true}
      />
    </div>
    {loginError && <p className='color-primary mt-15 otp-login-success'>{loginError}</p>}
    <div className='flex justify-center'>
      {!otpResend ?
        <p className="color-primary mb-20 mt-20 font-medium re-send-message">
          Didn’t receive the OTP?
          <span onClick={resendOtp} className={"color-primary mb-0 ml-5 font-medium " + (counter > 0 ? "disabled" : "enabled")}>
            Resend {counter > 0 && <span>in {counter} seconds</span>}
          </span>
        </p> : <p className="color-primary verify mb-40 mt-20 font-medium">OTP Resent !</p>}
    </div>
    <div className='flex justify-center mt-40'>
      <Button type="primary" className="font-medium" onClick={() => onSubmit(otp)}>Continue</Button>
    </div>
  </div>
}

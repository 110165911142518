import { Button } from "antd"
import { FullscreenWarn } from "assets/icons";
import UnberryModal from "components/dumb/UnberryModal"
import { useIsMobileDevice } from "hooks";
import { enableFullScreen } from "utils/helper"
import './style.scss'

const FullscreenWarning = ({fullScreenWarningVisible, setFullScreenWarningVisible, disqualifyFullScreen}) => {
    const isMobile = useIsMobileDevice();

    return (
        <UnberryModal
            visible={fullScreenWarningVisible}
            footer={false}
            className={"permission-modal"}
            width={isMobile ? '90%' : '60%'}
            closable={false}
        >
            <div className={`mt-30 mb-20`}>
                <div className="flex flex-col items-center justify-center mb-40">
                    <span className="fullscreen-icon mb-10">
                        <FullscreenWarn />
                    </span>
                    <h1 className={`color-primary align-center weight-600 ${isMobile ? "heading-mobile size-28": "size-36"}`}>
                        FullScreen Warning!
                    </h1>
                    <p className={`size-20 align-center color-primary`}>
                        This assessment is mandated to be take in full screen mode only, if you exit this window your candidature will be disqualified immediately. Do you want to resume or exit?
                    </p>
                </div>
                <div className={`flex items-center justify-center flex-gap-10 ${isMobile ? "flex-col" : ""}`}>
                    <Button
                        type="primary"
                        className="ready-btn font-medium br-20"
                        onClick={() => {
                            enableFullScreen({redirectToGames: () => {}})
                            setFullScreenWarningVisible(false)
                        }}
                        disabled={false}
                    >
                        Resume
                    </Button>
                    <Button
                        type="primary"
                        className="ready-btn font-medium br-20"
                        onClick={() => {
                            disqualifyFullScreen()
                        }}
                        disabled={false}
                    >
                        Exit Anyway
                    </Button>
                </div>
            </div>
        </UnberryModal>
    )
}

export default FullscreenWarning
export const modalBodyStyles = {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '50px 60px 90px 60px',
    background: 'rgba(46, 46, 46, 0.5)',
    mixBlendMode: 'normal',
    border: '2px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '5px'
}

export const modalMaskStyles = {
    backgroundColor: 'rgba(0,0,0,0.9)',
    // filter: 'blur(135px)'
}

export const otpStyles = {
    width: '70px',
    height: '80px',
    marginRight: '8px',
    fontSize: '25px',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.06)',
    borderRadius: '30px',
    outline: 'none',
    border: 'none'
}

export const errorStyles = {
    width: '70px',
    height: '80px',
    marginRight: '8px',
    fontSize: '25px',
    color: '#fff',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '5px',
    outline: 'none',
    border: '1px solid #f00'
}
import { useState } from "react"
import { useParams } from "react-router-dom"
import { Button, Steps } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import CheckableTag from 'antd/lib/tag/CheckableTag'
import Toast from "components/dumb/Toast"
import { useIsMobileDevice } from "hooks"
import { feedbackFeelingsArray } from "constants/globalConstants"
import feedbackApi from "services/apis/feedback"
import { storage } from "services/config/storage"
import './style.scss'

const FeedbackSection = ({ feedbackSubmitted, setFeedbackSubmitted }) => {
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        feeling: "",
        rating: "",
        comment: ""
    })
    const [error, setError] = useState({})

    const isMobile = useIsMobileDevice()
    const { userId, linkId } = useParams()

    /**
     * This function checks if a value is valid or not.
     * @param {value}
     * @return {Boolean}
     */
    const isValueInvalid = (value) => value === ""

    /**
     * This function checks if all the values are valid or not.
     * @return {isFeedbackValid} - boolean
     */
    const isFeedbackValid = () => {
        let isFeedbackValid = true
        const updatedError = { ...error }
        for (const value in values) {
            if (isValueInvalid(values[value])) {
                updatedError[value] = true
            } else {
                delete updatedError[value]
            }
        }
        setError({ ...updatedError })
        if (Object.keys(updatedError)?.length > 0) {
            isFeedbackValid = false

        }
        return isFeedbackValid
    }

    /**
     * This function handles the feedback submission.
     */

    const handleSubmitFeedback = async () => {
        if (isFeedbackValid()) {
            const payload = {
                userId,
                linkId,
                overallExperienceRating: values.rating + 1,
                moodRating: values.feeling,
                feedback: values.comment
            }
            try {
                setLoading(true)
                const res = await feedbackApi.sendFeedback(payload)
                if (res?.success) {
                    storage.destroy.all()
                    storage.set.isFeedbackSubmitted(userId)
                    setFeedbackSubmitted(true)
                }
            } catch (e) {
                Toast("error", e?.message)
                if(e?.message?.toLowerCase()?.includes("record already exists")) {
                    storage.set.isFeedbackSubmitted(userId)
                    setFeedbackSubmitted(true)
                }
            } finally {
                setLoading(false)
            }
        }
    }

    /**
     * This function handles the change for input value.
     * Checks validity of value changed as well.
     */
    const handleInputChange = (key, value) => {
        const updatedValues = { ...values }
        updatedValues[key] = value
        setValues({ ...updatedValues })
        if (isValueInvalid(value)) {
            setError(errState => ({ ...errState, [key]: true }))
        } else {
            setError(errState => {
                delete errState[key]
                return errState
            })
        }
    }

    return (
        <div className={`feedback-container flex flex-col items-start justify-center ${feedbackSubmitted ? "feedback-translate" : ""}`}>
            <div className={`mb-34 p-8`}>
                <p className='feedback-category-heading size-16 weight-400 m-0'>How are you feeling right now? *</p>
                <div className={`tags-container flex items-center flex-wrap mt-8`}>
                    {
                        feedbackFeelingsArray?.map(currentFeeling => <CheckableTag
                            key={currentFeeling.key}
                            checked={currentFeeling.key === values.feeling}
                            onChange={() => {
                                handleInputChange("feeling", currentFeeling.key)
                            }}
                        >
                            <currentFeeling.icon /> {currentFeeling.label}
                        </CheckableTag>)
                    }
                </div>
                {
                    error?.feeling ? <p className="size-12 font-italic text-error m-0 mt-4">Please choose a feeling</p> : <></>
                }
            </div>
            <div className={`mb-34 p-8`}>
                <p className='size-16 weight-400 m-0 feedback-category-heading'>Rate your experience on a scale of 1-10 *</p>
                <div className={`flex flex-col justify-center steps-container p-20 br-12 mt-8`}>
                    <Steps
                        direction="horizontal"
                        current={values.rating}
                        onChange={(val) => {
                            handleInputChange("rating", val)
                        }}
                        items={Array.from({ length: 10 }, (_, index) => index + 1)
                            .map(rating => ({
                                icon: <p key={rating} className={`${isMobile ? "size-12" : "size-16"} m-0`}>{rating}</p>,
                            }))
                        }
                        className={`${isMobile ? "mt-14" : ""}`}
                    />
                    <div className={`flex items-center justify-between mt-10`}>
                        <p className={`m-0 size-14 opacity-70`}>NOT FUN</p>
                        <p className={`m-0 size-14 opacity-70`}>SUPER FUN</p>
                    </div>
                </div>
                {
                    error?.rating ? <p className="size-12 font-italic text-error m-0 mt-4">Please give a rating</p> : <></>
                }
            </div>
            <div className={`mb-34 p-8`}>
                <p className='size-16 weight-400 m-0 feedback-category-heading'>Describe your overall experience. Feel free to drop in suggestions/feedback as well *</p>
                <TextArea
                    value={values.comment}
                    onChange={(e) => {
                        handleInputChange("comment", e.currentTarget.value)
                    }}
                    style={{
                        resize: 'none'
                    }}
                    rows={4}
                    placeholder="Enter your feedback/suggestions"
                    className='br-12 mt-8' />
                {
                    error?.comment ? <p className="size-12 font-italic text-error m-0 mt-4">Please give some comments</p> : <></>
                }
            </div>
            <div className='flex items-center justify-center mb-8'>
                <Button
                    loading={loading}
                    onClick={handleSubmitFeedback}
                    className='br-20 size-16 weight-500 submit-feedback-btn'
                >
                    Submit feedback
                </Button>
            </div>
        </div>
    )
}

export default FeedbackSection
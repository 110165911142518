import { Button } from "antd"
import { Fullscreen } from "assets/icons"
import UnberryModal from "components/dumb/UnberryModal"
import { enableFullScreen } from "utils/helper"
import './style.scss'

const FullscreenModal = ({ fullScreenPermissionVisible, setFullScreenPermissionVisible, isMobile, linkId, history }) => {
    return (
        <UnberryModal
            visible={fullScreenPermissionVisible}
            footer={false}
            className={"permission-modal"}
            width={isMobile ? '90%' : '60%'}
            onCancel={() => setFullScreenPermissionVisible(false)}
        >
            <div className={`mt-30 mb-20`}>
                <div className="flex flex-col items-center justify-center mb-40">
                    <span className="fullscreen-icon mb-10">
                    <Fullscreen />
                    </span>
                    <h1 className={`color-primary weight-600 align-center ${isMobile ? "heading-mobile size-28": "size-36"}`}>
                        FullScreen Mode
                    </h1>
                    <p className={`${isMobile ? "size-14" : "size-20"} align-center color-primary`}>
                        This assessment has to be taken in a full screen mode and you have to complete entire assessment in one sitting. Are you sure you are ready for that?
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <Button
                        type="primary"
                        className="ready-btn font-medium br-20"
                        onClick={() => {
                            enableFullScreen({
                                redirectToGames: () => {
                                    linkId ? history.push(`/games/${linkId}`) : history.push(`/games`) 
                                }
                            })
                        }}
                        disabled={false}
                    >
                        Ready!
                    </Button>
                </div>
            </div>
        </UnberryModal>
    )
}

export default FullscreenModal
const { EVENT_STATUS } = require('./consts');

/**
 * Get the parent app origin (Generally to grab iframe url)
 * @returns {string}
 */
const getParentOrigin = () => {
  const parentOrigin =
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;
  return parentOrigin.endsWith('/') ? parentOrigin.slice(0, -1) : parentOrigin;
};

/**
 * Send message to the parent app from this app (Assessment centre)
 * @param {any} message message to be sent
 * @return
 */
const sendMessageToGamecenter = (message) => {
  const url = getParentOrigin();
  const data = message;
  window.parent.postMessage(data, url);
};

/**
 * Event for game end, send gameId to parent app
 */
const sendGameplayEndMessage = () => {
  const message = { status: EVENT_STATUS.GAMEPLAY_END };
  sendMessageToGamecenter(message);
};

const sendMessageToGame = ({ message, origin }) => {
  const iframeEl = document.getElementById('game-frame')
  const frameWindow = iframeEl.contentWindow
  frameWindow.postMessage(message, origin)
}

export const GC = {
  sendGameplayEndMessage,
  sendMessageToGame
};


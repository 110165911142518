import { useCallback, useEffect, useRef, useState } from "react"
import { Button, Result, Select } from "antd"
import UnberryModal from "components/dumb/UnberryModal"
import { storage } from "services/config/storage"
import { useIsMobileDevice } from "hooks"
import { Cross, Dropdown, MicrophoneIcon } from "assets/icons"
import "./style.scss"

export const MicTest = ({ onSuccess = () => { } }) => {
  const [availableDevices, setAvailableDevices] = useState([])
  const [selectedDevice, setSelectedDevice] = useState("")
  const [audioSrc, setAudioSrc] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const isMobile = useIsMobileDevice()
  const tryAnotherMicCount = useRef(0)

  const saveAudioDevice = (deviceId) => {
    if (deviceId) {
      storage.set.micDevice(deviceId)
      setSelectedDevice(deviceId)
    }
  }

  const audioBlobToUrl = (blob) => {
    const audioUrl = URL.createObjectURL(blob)
    setAudioSrc(audioUrl)
  }

  const retest = () => {
    setAudioSrc(null)
    tryAnotherMicCount.current += 1
  }

  useEffect(() => {
    const handleDevices = (mediaDevices) => {
      const audioSources = mediaDevices.filter(
        ({ kind }) => kind === "audioinput"
      )
      setAvailableDevices(audioSources)
      if (audioSources.length && !selectedDevice) {
        const [firstSource] = audioSources
        saveAudioDevice(firstSource.deviceId)
      }
    }

    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.")
    } else {
      navigator.mediaDevices.enumerateDevices().then(handleDevices)
    }
  }, [])

  useEffect(() => {
    if (tryAnotherMicCount?.current > 1) {
      setIsModalVisible(true)
    } else {
      setIsModalVisible(false)
    }
  }, [tryAnotherMicCount?.current])

  return (
    <>
      <UnberryModal
        visible={isModalVisible}
        footer={false}
        width={"50%"}
        closeIcon={<Cross />}
        keyboard={false}
        onCancel={() => {
          setIsModalVisible(false)
        }}
      >
        <Result
          status="warning"
          title={
            <p className="color-primary size-18">
              If you think you are speaking loud enough and still not able to hear it back, we would suggest using a different device so that your assessment experience is smooth.
            </p>
          }
        />
      </UnberryModal>
      <div className="audio-test-div">
        <div className="flex items-center">
          <h1 className="color-primary font-medium">
            Let's check your microphone settings
          </h1>
        </div>

        <div className="mt-20">
          <h4 className="color-primary font-medium opacity-70">
            Select Microphone
          </h4>
          <div className="mt-2 flex items-center">
            <Select
              className={`size-14 width-${isMobile ? "300" : "400"}`}
              suffixIcon={<Dropdown />}
              value={selectedDevice}
              options={availableDevices.map(({ label, deviceId }) => ({
                label,
                value: deviceId,
              }))}
              onChange={saveAudioDevice}
            />
          </div>
          {audioSrc ? (
            <div className="mx-auto mt-12 w-full lg:w-1/2">
              <AudioPlayer src={audioSrc} isMobile={isMobile} />
            </div>
          ) : (
            <AudioRecorder
              hideTimer
              audioDeviceId={selectedDevice}
              getBlobData={audioBlobToUrl}
              onSuccess={onSuccess}
              isMobile={isMobile}
            />
          )}
        </div>

        {audioSrc && (
          <div className="mt-40">
            <p className="size-14 color-primary font-medium opacity-70">Were you able to hear the recorded audio?</p>
            <div className="flex items-stretch">
              <Button
                type="primary"
                className="br-20 width-50"
                onClick={onSuccess}
              >
                Yes
              </Button>
              <button
                className="secondary-btn br-20 pb-6 pt-6 pl-10 pr-10 ml-10 cursor-pointer"
                onClick={retest}
              >
                No, try another microphone
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const AudioPlayer = ({ src, isMobile }) => {

  return (
    <div className="flex items-center justify-start mt-20">
      <audio
        controls={true}
        autoPlay={true}
        className={`width-${isMobile ? "300" : "400"}`}
      >
        <source src={src} type="audio/mp3" />
      </audio>
    </div>
  )
}

const AudioRecorder = ({
  audioDeviceId,
  getBlobData = () => { },
  onStatusChange = () => { },
  isMobile,
  onSuccess
}) => {
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [volumeLevel, setVolumeLevel] = useState(0)
  const [audioContext, setAudioContext] = useState(null)
  const [isValidAudio, setIsValidAudio] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const buttonRef = useRef(null)

  const requestRecorder = useCallback(async () => {
    const deviceId = audioDeviceId
    const constraints = { audio: deviceId ? { deviceId } : true }
    const stream = await navigator.mediaDevices.getUserMedia(constraints)

    // logic for showing microphone input level
    const audioContext = new AudioContext()
    const analyser = audioContext.createAnalyser()
    const microphone = audioContext.createMediaStreamSource(stream)
    const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1)

    analyser.smoothingTimeConstant = 0.8
    analyser.fftSize = 1024

    microphone.connect(analyser)
    analyser.connect(scriptProcessor)
    scriptProcessor.connect(audioContext.destination)
    scriptProcessor.onaudioprocess = function () {
      const array = new Uint8Array(analyser.frequencyBinCount)
      analyser.getByteFrequencyData(array)
      const arraySum = array.reduce((a, value) => a + value, 0)
      const average = arraySum / array.length
      setVolumeLevel(average)
    }

    // Saving context to close it once the recording is stopped
    setAudioContext(audioContext)

    return new MediaRecorder(stream)
  }, [audioDeviceId])

  const initializeRecorder = useCallback(async () => {
    try {
      const recorderInstance = await requestRecorder()
      setRecorder(recorderInstance)
    } catch (error) {
      console.error(error)
    }
  }, [requestRecorder])

  // this method gets fired when recorder.stop method is invoked
  const handleData = useCallback(
    async (e) => {
      audioContext.close()
      if (!isValidAudio) {
        setErrorMessage(
          "No audio detected, please try changing your microphone or system setting"
        )
        setRecorder(null)
        return
      }

      // sending blob data for audioTestingModal component
      getBlobData(e.data)

    },
    [audioContext, getBlobData, isValidAudio]
  )

  const startRecording = () => {
    setIsRecording(true)
    onStatusChange("recording")
  }

  const stopRecording = () => {
    setIsRecording(false)
    onStatusChange("inactive")
  }

  useEffect(() => {
    if (volumeLevel > 10) {
      setTimeout(() => {
        setIsValidAudio(true)
      }, 500)
    }
  }, [volumeLevel])

  useEffect(() => {
    if (recorder === null) {
      if (isRecording) {
        initializeRecorder()
      }
      return
    }

    // Manage recorder state.
    if (isRecording && recorder.state !== "recording") {
      recorder.start()

      // resuming audio context which give microphone volume info
      if (audioContext) audioContext.resume()
    } else if (!isRecording) {
      // execute stop only when state is inactive -> Android Fix
      recorder.state !== "inactive" && recorder.stop()

      // Suspends the progression of time in the audio context, temporarily halting audio hardware access and reducing CPU/battery usage in the process.
      if (audioContext) audioContext.suspend()
    }

    recorder.addEventListener("dataavailable", handleData)
    return () => {
      recorder.removeEventListener("dataavailable", handleData)
    }
  }, [recorder, isRecording, audioContext, handleData, initializeRecorder])

  const audioBlocks = Array.from({ length: 6 }, (_, index) => {
    const numberOfBlocksToColor = Math.round(volumeLevel / 6)

    return (
      <div
        key={index}
        className={`audio-block br-4 ${index < numberOfBlocksToColor ? "active-audio-block" : "inactive-audio-block"}`}
      />
    )
  })

  return (
    <div
      className={`mt-10 flex flex-col items-center`}
    >
      {isRecording ? (
        <>
          <Button
            ref={buttonRef}
            type="primary"
            className="weight-400 size-12 br-20 width-110 flex justify-center items-center"
            onClick={stopRecording}
          >
            <div className="flex items-center mr-4">{audioBlocks}</div>
            SUBMIT
          </Button>
        </>
      ) : (
        <>
          <Button
            type="primary"
            className="weight-400 size-12 br-20 width-80 mt-10 pt-6 pb-6"
            onClick={startRecording}
          >
            <span className="mic-icon">
              <MicrophoneIcon />
            </span>
          </Button>
          <p className="size-14 color-primary font-medium opacity-70 mt-4">
            Click mic button and start speaking
          </p>
          {!!errorMessage && (
            <div className="flex flex-col items-start">
              <p className={`color-primary font-medium opacity-70 width-${isMobile ? "300" : "400"} mt-40`}>{errorMessage}</p>
              <Button
                type="primary"
                className="weight-500 size-14 br-20 width-150"
                onClick={onSuccess}
              >Proceed Anyway</Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

const LS_KEY = 'auth_token';
const LS_FC_KEY = '__fc_data__'; // FreshChat localstorage key
const CB_KEY = 'url';
const LS_SHOW_GAMEPLAY_ONLY = 'LS_SHOW_GAMEPLAY_ONLY';
const LS_FEEDBACK_ALLOWED = 'LS_FEEDBACK_ALLOWED';
const LOGOS_URLS = 'companies_logos';
const LS_TK_EX = 'ck';
const LS_LINK_KEY = 'link';
const LS_ORG_INFO = "orgInfo";
const LS_ORG_ID = 'org_id'
const LS_ALL_TABS_KEY = 'all_tab_switch';
const LS_USER_IMG = 'img'
const LS_CG_STAT = 'config_stat';
const LS_WEB_CAM = 'webcam_device';
const LS_MIC = 'mic_device';
const LS_LG_TYPE = 'login_type';
const LS_FB_BOOL = 'isFeedbackSubmitted'
const LS_DISQUALIFIED_BOOL = 'userDisqualified'
const LS_VIDEO_SESSION = 'video_session_id'
const LS_VIDEO_ORDER = 'video_order_count'
// let storageType = checkStorage();
// function checkStorage() {
//   window.localStorage.setItem('ex-fg', 'check');
//   if (window.localStorage.getItem('ex-fg')) return 'localStorage';
//   else return 'sessionStorage';
// }

const set = {
  authToken: (data) => {
    localStorage.setItem(LS_KEY, JSON.stringify(data));
  },
  lastLevelPlayed: (data) => {
    localStorage.setItem('last_level', JSON.stringify(data));
  },
  listOfGames: (data) => {
    localStorage.setItem('list_of_Games', JSON.stringify(data));
  },
  listOfPlayedGamesIds: (data) => {
    localStorage.setItem('list_of_played_game_ids', JSON.stringify(data));
  },
  fcData: (data) => {
    localStorage.setItem(LS_FC_KEY, JSON.stringify(data));
  },
  url: (data) => {
    localStorage.setItem(CB_KEY, JSON.stringify(data));
  },
  showGameplayOnly: (data) => {
    sessionStorage.setItem(LS_SHOW_GAMEPLAY_ONLY, JSON.stringify(data));
  },
  feedbackAllowed: (data) => {
    sessionStorage.setItem(LS_FEEDBACK_ALLOWED, JSON.stringify(data));
  },
  companiesLogos: (data) => {
    localStorage.setItem(LOGOS_URLS, JSON.stringify(data));
  },
  tokenExpiry: (data) => {
    localStorage.setItem(LS_TK_EX, JSON.stringify(data));
  },
  linkId: (data) => {
    localStorage.setItem(LS_LINK_KEY, JSON.stringify(data));
  },
  allTabSwitchCount: (count) => {
    const { id: userId } = storage.get.fcData()
    localStorage.setItem(`${LS_ALL_TABS_KEY}-${userId}`, JSON.stringify(count));
  },
  userImg: (data) => {
    localStorage.setItem(LS_USER_IMG, JSON.stringify(data));
  },
  configStat: (data) => {
    localStorage.setItem(LS_CG_STAT, JSON.stringify(data));
  },
  webCam: (data) => {
    localStorage.setItem(LS_WEB_CAM, JSON.stringify(data));
  },
  micDevice: (data) => {
    localStorage.setItem(LS_MIC, JSON.stringify(data));
  },
  loginType: (data) => {
    localStorage.setItem(LS_LG_TYPE, JSON.stringify(data))
  },
  isFeedbackSubmitted: (userId) => {
    localStorage.setItem(`${LS_FB_BOOL}-${userId}`, JSON.stringify(true))
  },
  userDisqualified: () => {
    sessionStorage.setItem(LS_DISQUALIFIED_BOOL, JSON.stringify(true))
  },
  videoSessionId: (data) => {
    localStorage.setItem(LS_VIDEO_SESSION, JSON.stringify(data))
  },
  videoOrderCount: (data) => {
    localStorage.setItem(LS_VIDEO_ORDER, JSON.stringify(data))
  },
  orgInfo: (data) => {
    localStorage.setItem(LS_ORG_INFO, JSON.stringify(data))
  },
  orgId: (data) => {
    localStorage.setItem(LS_ORG_ID, JSON.stringify(data))
  },
};

const get = {
  authToken: () => {
    return JSON.parse(localStorage.getItem(LS_KEY));
  },
  lastLevelPlayed: () => {
    return JSON.parse(localStorage.getItem('last_level'));
  },
  listOfGames: () => {
    return JSON.parse(localStorage.getItem('list_of_Games'));
  },
  listOfPlayedGamesIds: () => {
    return JSON.parse(localStorage.getItem('list_of_played_game_ids'));
  },
  fcData: () => {
    return JSON.parse(localStorage.getItem(LS_FC_KEY));
  },
  url: () => {
    return JSON.parse(localStorage.getItem(CB_KEY));
  },
  showGameplayOnly: () => {
    return JSON.parse(sessionStorage.getItem(LS_SHOW_GAMEPLAY_ONLY));
  },
  feedbackAllowed: () => {
    return JSON.parse(sessionStorage.getItem(LS_FEEDBACK_ALLOWED));
  },
  companiesLogos: () => {
    return JSON.parse(localStorage.getItem(LOGOS_URLS));
  },
  tokenExpiry: () => {
    return JSON.parse(localStorage.getItem(LS_TK_EX));
  },
  linkId: () => {
    return JSON.parse(localStorage.getItem(LS_LINK_KEY));
  },
  allTabSwitchCount: () => {
    const { id: userId } = storage.get.fcData()
    return JSON.parse(localStorage.getItem(`${LS_ALL_TABS_KEY}-${userId}`));

  },
  userImg: () => {
    return JSON.parse(localStorage.getItem(LS_USER_IMG));
  },
  configStat: () => {
    return JSON.parse(localStorage.getItem(LS_CG_STAT));
  },
  webCam: () => {
    return JSON.parse(localStorage.getItem(LS_WEB_CAM));
  },
  micDevice: () => {
    return JSON.parse(localStorage.getItem(LS_MIC));
  },
  loginType: () => {
    return JSON.parse(localStorage.getItem(LS_LG_TYPE));
  },
  isFeedbackSubmitted: (userId) => {
    return JSON.parse(localStorage.getItem(`${LS_FB_BOOL}-${userId}`));
  },
  userDisqualified: () => {
    return JSON.parse(sessionStorage.getItem(LS_DISQUALIFIED_BOOL));
  },
  videoSessionId: () => {
    return JSON.parse(localStorage.getItem(LS_VIDEO_SESSION));
  },
  videoOrderCount: () => {
    return JSON.parse(localStorage.getItem(LS_VIDEO_ORDER));
  },
  orgInfo: () => {
    return JSON.parse(localStorage.getItem(LS_ORG_INFO));
  },
  orgId: () => {
    return JSON.parse(localStorage.getItem(LS_ORG_ID));
  },
};

const destroy = {
  authToken: () => {
    localStorage.removeItem(LS_KEY);
  },
  fcData: () => {
    localStorage.removeItem(LS_FC_KEY);
  },
  url: () => {
    localStorage.removeItem(CB_KEY);
  },

  showGameplayOnly: () => {
    sessionStorage.removeItem(LS_SHOW_GAMEPLAY_ONLY);
  },
  feedbackAllowed: () => {
    sessionStorage.removeItem(LS_FEEDBACK_ALLOWED);
  },
  configStat: () => {
    localStorage.removeItem(LS_CG_STAT);
  },
  webCam: () => {
    localStorage.removeItem(LS_WEB_CAM);
  },
  companiesLogos: () => {
    localStorage.removeItem(LOGOS_URLS);
  },
  tabSwitchCounts: (userId) => {
    localStorage.removeItem(`${LS_ALL_TABS_KEY}-${userId}`);
  },
  listOfPlayedGamesIds: () => {
    localStorage.removeItem('list_of_played_game_ids')
  },
  listOfGames: () => {
    localStorage.removeItem('list_of_Games')
  },
  userDisqualified: () => {
    sessionStorage.removeItem(LS_DISQUALIFIED_BOOL);
  },
  all: () => localStorage.clear(),
  allExceptFeedbackSubmittedAndUserDisqualified: (userId) => {
    storage.destroy.listOfPlayedGamesIds()
    storage.destroy.listOfGames()
    storage.destroy.url()
    storage.destroy.configStat()
    storage.destroy.webCam()
    storage.destroy.companiesLogos()
    storage.destroy.tabSwitchCounts(userId)
  },

};

export const storage = {
  set,
  get,
  destroy,
};
